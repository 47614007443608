import Utilities from "Utilities/global";

const totalFuelConsumptionPayload = {
    withMetadata: true,
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "total_fuel_consumption_fk",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "totalFOMassRate_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_hsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_mdo_mgo_fk",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lng_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lpg_but_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lpg_prop_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_eth_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_meth_cons_calc",
                    aggregation: "SUM"
                },
            ]
        }
    ]
};


const totalFuelConsumptionAllMetricsPayload = {
    withMetadata: true,
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "total_fuel_consumption_fk",
                    aggregation: "SUM"
                },
                {
                    metricName: "commonDGFOMassRateAbsolute",
                    aggregation: "SUM"
                },
                {
                    metricName: "boilerCommonFoMassRateAbsolute",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumptionAbsolute",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "totalFOMassRate_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "me_1_foConsumption_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "ge_1_foConsumption_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "bo_1_foConsumption_calc", 
                    aggregation: "SUM"
                },
                {
                    metricName: "total_hsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lsfo_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lng_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lpg_but_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_lpg_prop_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_eth_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_meth_cons_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "total_mdo_mgo_fk",
                    aggregation: "SUM"
                },
            ]
        }
    ]
};

const updateTotalFuelConsumption = (id, data, updateState, extraChartConfigs, widget) => {
    // handle no data case
    if(!data?.series?.length) return updateState(id, {}, widget?.id);

    const totalFuelSensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_fuel_consumption_fk' && obj.metricCategory === 'VESSEL')?.value/1000;
    const totalFuelMESensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'foVolConsumptionAbsolute')?.value !==  null ? data?.series[0]?.values?.find((obj) => obj.metricName === 'foVolConsumptionAbsolute')?.value/1000 : null;
    const totalFuelGESensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'commonDGFOMassRateAbsolute')?.value !== null ? data?.series[0]?.values?.find((obj) => obj.metricName === 'commonDGFOMassRateAbsolute')?.value/1000 : null;
    const totalFuelBOSensorsValue = data?.series[0]?.values?.find((obj) => obj.metricName === 'boilerCommonFoMassRateAbsolute')?.value !== null ? data?.series[0]?.values?.find((obj) => obj.metricName === 'boilerCommonFoMassRateAbsolute')?.value/1000 : null;

    const totalFuelSensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelSensorsValue, 2));
    const totalFuelMESensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelMESensorsValue, 2));
    const totalFuelGESensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelGESensorsValue, 2));
    const totalFuelBOSensorsTemp = Utilities.renderNumber(Utilities.roundToDigits(totalFuelBOSensorsValue, 2));

    const totalFuelSensorsMissingTemp = totalFuelSensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'total_fuel_consumption_fk' && obj.metricCategory === 'VESSEL')?.missingValues;
    const totalFuelMESensorsMissingTemp = totalFuelMESensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'foVolConsumptionAbsolute')?.missingValues;
    const totalFuelGESensorsMissingTemp = totalFuelGESensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'commonDGFOMassRateAbsolute')?.missingValues;
    const totalFuelBOSensorsMissingTemp = totalFuelBOSensorsValue && data?.series[0]?.values?.find((obj) => obj.metricName === 'boilerCommonFoMassRateAbsolute')?.missingValues;

    const totalFuelReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'totalFOMassRate_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    const totalFuelMEReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'me_1_foConsumption_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    const totalFuelGEReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'ge_1_foConsumption_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    const totalFuelBOReportsTemp = Utilities.renderNumber(Utilities.roundToDigits(data?.series[0]?.values?.find((obj) => obj.metricName === 'bo_1_foConsumption_calc' && obj.metricCategory === 'TELEGRAM')?.value, 2));
    
    const totalHSFOTemp = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_hsfo_cons_calc')?.value;
    const totalLSFOTemp = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_lsfo_cons_calc')?.value;
    const totalLngCons = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_lng_cons_calc')?.value;
    const totalLpgButCons = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_lpg_but_cons_calc')?.value;
    const totalLpgPropCons = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_lpg_prop_cons_calc')?.value;
    const totalEthCons = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_eth_cons_calc')?.value;
    const totalMethCons = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_meth_cons_calc')?.value;
    const totalMdoMgo = data?.series[0]?.values?.find((obj) => obj.metricName === 'total_mdo_mgo_fk')?.value;

    const updatedData = {
        data: {
            totalFuelSensors: (totalFuelSensorsTemp !== '-') && totalFuelSensorsTemp,
            totalFuelMESensors: (totalFuelMESensorsTemp !== '-') && totalFuelMESensorsTemp,
            totalFuelGESensors: (totalFuelGESensorsTemp !== '-') && totalFuelGESensorsTemp,
            totalFuelBOSensors: (totalFuelBOSensorsTemp !== '-') && totalFuelBOSensorsTemp,
            
            totalFuelSensorsMissing: (totalFuelSensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelSensorsMissingTemp, 1)),
            totalFuelMESensorsMissing: (totalFuelMESensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelMESensorsMissingTemp, 1)),
            totalFuelGESensorsMissing: (totalFuelGESensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelGESensorsMissingTemp, 1)),
            totalFuelBOSensorsMissing: (totalFuelBOSensorsMissingTemp > Utilities.missingValuesLimit) && Utilities.renderNumber(Utilities.roundToDigits(totalFuelBOSensorsMissingTemp, 1)),

            totalFuelReports: (totalFuelReportsTemp !== '-') && totalFuelReportsTemp,
            totalFuelMEReports: (totalFuelMEReportsTemp !== '-') && totalFuelMEReportsTemp,
            totalFuelGEReports: (totalFuelGEReportsTemp !== '-') && totalFuelGEReportsTemp,
            totalFuelBOReports: (totalFuelBOReportsTemp !== '-') && totalFuelBOReportsTemp,
            
            fuelConsumptionTypesData: {
                HFO: totalHSFOTemp > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalHSFOTemp, 2)),
                LFO: totalLSFOTemp > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalLSFOTemp, 2)),
                'MDO-MGO': totalMdoMgo > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalMdoMgo, 2)),
                LNG: totalLngCons > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalLngCons, 2)),
                'LPG-Butane': totalLpgButCons > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalLpgButCons, 2)),
                'LPG-Propane': totalLpgPropCons > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalLpgPropCons, 2)),
                Ethanol: totalEthCons > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalEthCons, 2)),
                Methanol: totalMethCons > 0 && Utilities.renderNumber(Utilities.roundToDigits(totalMethCons, 2)),
            }
        }
    }

    updateState(id, updatedData, widget?.id);
}

export {totalFuelConsumptionPayload, totalFuelConsumptionAllMetricsPayload, updateTotalFuelConsumption}
