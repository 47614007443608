import React, {useEffect, useState} from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import service from 'common/js/service';
import ReportsHeader from 'reports/reportsHeader';
import MapComponent from 'components/map/map';
import Layout from 'components/core/Layout';
import BarChart from 'components/charts/barChart/barChart';
import ReportsService from 'common/js/reports';
import {fleetDailyInfoPayload, fleetDailyInfo as updateFleetDailyInfo, fleetDailyInfoColumnDefs, fleetDailyInfoColumnDefsSensorDataOnly} from 'widgets/vesselData/fleetDailyInfo';
import {fcrChartPayload, fcr as updateFcr} from 'widgets/mainEngine/fcrFleetChart';
import ComponentWithIcon from 'components/componentWithIcon/componentWithIcon';
import {fleetLog as updateFleetLog, columnDefs} from 'widgets/events/fleetLog';
import TableComp from 'components/table/table';
import EventTypeGroup from 'components/eventTypeGroup/eventTypeGroup';
import VesselNameBox from 'components/vesselNameBox/vesselNameBox';
import VesselNameHorizontal from 'components/vesselNameHorizontal/vesselNameHorizontal';
import DoubleData from 'components/table/doubleData/doubleData';
import ReportPeriod from 'components/table/reportPeriod/reportPeriod';
import Utils from 'Utilities/global';
import { useDispatch, useSelector } from 'react-redux';
import {
    reportsStore,
    vesselGroupStore,
    vesselStore,
    vesselGroupUtils,
    reportsUtils,
    vesselUtils,
} from 'common/store/storeUtils';

let isMounted = false;
let currentVesselGroupId = 'none';
let firstTelegramDate = null;

let registrationWidgets = [];

const DailyGroupReport = (props) => {
    const reportsService = new ReportsService();
    const [requestOnGoing, setRequestOnGoing] = useState({
        fleetPosition: false,
        fleetDailyInfo: false,
        fcr: false,
        fleetLog: false
    });
    const [init, setInit] = useState(false);
    const [widgets, setWidgets] = useState({layout: []});
    const [fcr, setFcr] = useState({});
    const [fleetDailyInfo, setFleetDailyInfo] = useState([]);
    const [fleetLog, setFleetLog] = useState([]);
    const [activeDates, setActiveDates] = useState({});
    const [periods, setPeriods] = useState()
    // store vars for JSX
    const reportsStoreJSX = useSelector(state => state.reports);
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const userStoreJSX = useSelector(state => state.user);

    const dispatch = useDispatch();

    registrationWidgets = [
        {widgetId: 'fleetDailyInfo', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: fleetDailyInfoPayload, type: 'table'},
        {widgetId: 'fcr', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true}, payload: fcrChartPayload, type: 'basicChart'},
        {widgetId: 'fleetLog', inDashboard: false, vesselIds: [vesselStore().selectedVessel], singleRequest: {value: true, externalURequest: {value: true, request: () => { return {method: service.getGroupDailyTimelineEvents, params:
            { value: vesselGroupStore().selectedVesselGroup, datetime: reportsStore().fromTo.to }
        }}}}, payload: {}, type: 'table'}
    ];

    const updateRequestOnGoing = (key, value) => {
        setRequestOnGoing((currentRequestOnGoing) => {return {...currentRequestOnGoing, [key]: value}})
    }

    const stateMapper = (key, value) => {
        const mapper = {
            fcr: setFcr,
            fleetDailyInfo: setFleetDailyInfo,
            fleetLog: setFleetLog,
        }
        return mapper[key](value);
    }

    const reportsResponseMapper = (response, key) => {
        const mapper = {
            fcr: updateFcr,
            fleetDailyInfo: updateFleetDailyInfo,
            fleetLog: updateFleetLog,
        }
        return mapper[key](key, response, stateMapper);
    }

    const updatePeriods = async () => {
        const res = await service.getReportPeriods(vesselUtils.fleetVesselsIds(), reportsStore().fromTo.from);
        if (res?.data) {
            setPeriods(reportsUtils.getSortedDailyGroupPeriods(res.data));
        }
    }

    const register = () => {
        if(vesselUtils.fleetVesselsIds() === -1) return;
        reportsService.registerReports(vesselUtils.fleetVesselsIds(), registrationWidgets, reportsResponseMapper, updateRequestOnGoing);
        updatePeriods()
    }

    const updateFromTo = (dates) => {
        if(!isMounted) return;
        // we need to send to the paylods just a small period of the selected picker day
        // and the back end is responsible to answer with the correct data period according the telegrams
        const from = moment(dates.from).utc().set({hour:12, minute:0, second:0, millisecond:0}).valueOf();
        const to = moment(dates.to).utc().set({hour: 13, minute:0, second:0 ,millisecond:0}).valueOf();
        dispatch({type: 'reports/setFromTo', payload: {from, to}});
    };

    const goToDates = () => {
        reportsUtils.updateFromTo(
            dispatch, 
            { 
                from: reportsStore().fromTo.from, 
                to: reportsStore().fromTo.to 
            }, 
            reportsStore().fromToChanged
        );
        register();
    }

    const getWidgetsPreferences = () => {
        return {
            className: 'padding-left-right-7',
            layout: [
                {
                    columns: [
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                            components: [
                                {component: MapComponent, id: 'fleetPosition', style: {height: '400px'}, props: {}, title: "GROUP'S LATEST POSITION"}
                            ]
                        },
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                            components: [
                                {component: TableComp, id: 'fleetDailyInfo', style: {height: 'auto'}, props: {}, title: "GROUP'S INFO"}
                            ]
                        },
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                            components: [
                                {component: BarChart, id: 'fcr', style: {height: '340px'}, props: {}, title: "FUEL CONSUMPTION"}
                            ]
                        },
                        {
                            grid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 12},
                            components: [
                                {component: ComponentWithIcon, title: "GROUP'S LOG", id: 'fleetLog', style: {height: 'auto'}, props: {}}
                            ]
                        }
                    ]
                }
            ]
        };
    };

    const createLayoutProps = (data, props=widgets) => {
        props.layout = props.layout.map(newLayout => {
            newLayout.columns = newLayout.columns.map(newColData => {
                ('components' in newColData) && (newColData.components = newColData.components.map(newComponent => {
                    if(('id' in newComponent) && !('element' in newComponent)) {
                        if (newComponent.id in data) newComponent.props = data[newComponent.id];
                        newComponent.requestOnGoing = requestOnGoing;
                    }
                    return newComponent;
                }));
                ('layout' in newColData) && (newColData = createLayoutProps(data, newColData));
                return newColData;
            });
            return newLayout;
        });
        return props;
    }

    const getTelegramConfiguration = async () => {
        return new Promise((resolve) => {
            let isVesselGroupTelegramConfigured = false,
            lastTelegramDate = reportsStore().fromTo.from,
            firstTelegramDateIsSet = false;

            reportsUtils.getTelegramsConfig({vesselIds: vesselUtils.fleetVesselsIds() }).then(response => {
                // if at least one vessel has telegram configured, we should get the active dates to display on datepicker
                if (response?.data) {
                    let nullReportConfig = false;
                    vesselUtils.fleetVesselsIds().forEach(fleetVesselId => {
                        if(!nullReportConfig && response?.data[fleetVesselId].firstTelegramDate > firstTelegramDate) firstTelegramDate = response?.data[fleetVesselId].firstTelegramDate;
                        if(!response?.data[fleetVesselId].hasTelegramConfiguration) {
                            firstTelegramDate = null;
                            nullReportConfig = true;
                        }
                        if (response?.data[fleetVesselId].hasTelegramConfiguration && response?.data[fleetVesselId].lastTelegramDate) {
                            isVesselGroupTelegramConfigured = true;

                            // when we find the first vessel that has telegram configuration, then assign the lastTelegramDate to be it's lastTelegramDate
                            // after, for every vessel that has telegram configuration compare it's date with the lastTelegramDate, and if it is higher, then assign that date to be the lastTelegramDate 
                            // note: the final lastTelegramDate will be the max between all telegram dates (for vessels with telegram configuration)
                            if(!firstTelegramDateIsSet) {
                                lastTelegramDate = response.data[fleetVesselId].lastTelegramDate;
                                firstTelegramDateIsSet = true;
                            }
                            else if(moment(response?.data[fleetVesselId].lastTelegramDate).isAfter(moment(lastTelegramDate))) lastTelegramDate = response.data[fleetVesselId].lastTelegramDate;
                        }
                    })
                }
                dispatch({type: 'reports/setIsTelegramConfigured', payload: isVesselGroupTelegramConfigured})
                resolve({isVesselGroupTelegramConfigured, lastTelegramDate});
            })
        });
    }

    const getMonthlyReportsDates = async (selectedMonth) => {
        return new Promise((resolve) => {
            let from = moment(Utils.nowOrDemo()).subtract(1, 'days').set({hour:3,minute:0,second:0,millisecond:0}).valueOf();

            if(selectedMonth) from = moment(selectedMonth).set({hour:12,minute:0,second:0,millisecond:0}).valueOf();

            reportsUtils.getTelegramsMonthlyDates({
                vesselIds: vesselUtils.fleetVesselsIds(),
                datetime: from
            }).then(activeDatesInMonth => {
                if (activeDatesInMonth?.data?.length > 0) setActiveDates(activeDatesInMonth.data);
                const lastActiveDay = activeDatesInMonth.data[activeDatesInMonth.data.length - 1];
                resolve(lastActiveDay);
            });
        });
    }

    const initToDates = (timeFrom, timeTo) => {
        reportsUtils.updateFromTo(
            dispatch, 
            { 
                from: timeFrom, 
                to: timeTo 
            }, 
            reportsStore().fromToChanged
        );

        setTimeout(() => dispatch({type: 'reports/setFromTo', payload: {from: timeFrom, to: timeTo}}));

        setInit(true);

        if(vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id) vesselGroupUtils.setSelectedAndChange(dispatch, vesselGroupStore().assignedVesselGroup.id)
        if(vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId) dispatch({ type: 'vessel/setSelectedVessel', payload: vesselStore().allVesselsObj.vesselId });

        isMounted = true;
        setWidgets(getWidgetsPreferences());
        register();
        currentVesselGroupId = vesselGroupStore().selectedVesselGroup;
    }

    const getLastActiveDayAndInit = async (telegramConfiguration) => {
        const lastActiveDay = telegramConfiguration.isVesselGroupTelegramConfigured
            ? await getMonthlyReportsDates(telegramConfiguration.lastTelegramDate)
            : moment(Utils.nowOrDemo()).subtract(1, 'day').set({hour:12,minute:0,second:0,millisecond:0}).valueOf();
        
        const timeTo = moment(lastActiveDay).utc().set({hour:12,minute:0,second:0,millisecond:0}).valueOf();
        const timeFrom = moment(timeTo).subtract(1, 'hours').valueOf();
        dispatch({type: 'reports/setFromTo', payload: { from: timeFrom, to: timeTo }})
        initToDates(timeFrom, timeTo);
    }

    const mountFunction = async () => {
        const telegramConfiguration = await getTelegramConfiguration();
        getLastActiveDayAndInit(telegramConfiguration);
    }

    useEffect(() => {
        const controller = new AbortController();
        Utils.signal = controller.signal;

        // if initially no vessel group is selected, then select the user's default group
        if(vesselGroupStoreJSX.selectedVesselGroup === vesselGroupStoreJSX.allVesselGroupsObj.id) dispatch({ type: 'vesselGroup/setSelectedVesselGroup', payload: userStoreJSX.user.defaultGroup });
        
        mountFunction();

        return () => {
            controller.abort();
            isMounted = false;
            Utils.signal = null;
            currentVesselGroupId = 'none';
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!vesselStore().selectedVessel) return;
        if(!isMounted) return;
        if(!init) return;
        if(vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId) setTimeout(() => props.history.push('/reports/dailyVesselReport'));
    // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    useEffect(() => {
        if(!vesselGroupStore().selectedVesselGroup) return;
        if(!isMounted) return;
        if(!init) return;
        if(vesselGroupStore().selectedVesselGroup !== currentVesselGroupId) {
            currentVesselGroupId = vesselGroupStore().selectedVesselGroup;

            const dates = {
                from: moment(Utils.nowOrDemo()).utc().subtract(1, 'days').valueOf(),
                to: Utils.nowOrDemo()
            }
            updateFromTo( dates );
            mountFunction();
        }
    // eslint-disable-next-line
    }, [vesselGroupStoreJSX.selectedVesselGroup]);

    const data = {
        fleetPosition: {
            reports: true,
            toDateIsToday: true,
            dailyGroupReport: true,
            fromTo: reportsStoreJSX.fromTo,
            fromToChanged: reportsStoreJSX.fromToChanged,
            canInitMap: init
        },
        fleetDailyInfo: {
            columns: reportsStoreJSX.isTelegramConfigured ? fleetDailyInfoColumnDefs : fleetDailyInfoColumnDefsSensorDataOnly,
            items: fleetDailyInfo,
            frameworkComponents: { vesselNameBox: VesselNameBox, doubleData: DoubleData, reportPeriod: ReportPeriod },
            allowSorting: false,
            loading: requestOnGoing.fleetDailyInfo
        },
        fcr: {
            chartData: fcr,
            noStack: false,
            exportingChartTitle: 'FUEL CONSUMPTION',
            loading: requestOnGoing.fcr
        },
        fleetLog: {
            columns: columnDefs,
            component: TableComp,
            items: fleetLog,
            frameworkComponents: { eventTypeGroup: EventTypeGroup, vesselNameHorizontal: VesselNameHorizontal },
            iconName: 'eventsBigIcon',
            componentGrid: {xs: 12, sm: 12, md: 12, lg: 12, xl: 9},
            iconGrid: {xs: 'hidden', sm: 'hidden', md: 'hidden', lg: 'hidden', xl: 3},
            loading: requestOnGoing.fleetLog
        }
    };

    return (
        <div className="reports main-content-padding">
            <ReportsHeader from={reportsStoreJSX.fromTo.from} to={reportsStoreJSX.fromTo.to} updateFromTo={updateFromTo} 
             goToDates={goToDates} activeDatesInMonth={activeDates} getMonthlyReportsDates={getMonthlyReportsDates} 
             isTelegramConfigured={reportsStoreJSX.isTelegramConfigured} firstTelegramDate={firstTelegramDate}
             sourceTypes={reportsUtils.getAvailableDailyGroupSourceTypes()} periods={periods} hasData={true}/>
            <div className="reports__widgets">
                <Layout {...createLayoutProps(data)} />
            </div>
        </div>
    );

}


export default withRouter(DailyGroupReport);
