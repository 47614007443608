import React from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import classNames from 'classnames';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);

const LegFuelConsumption = (props) => { 
   
	// check if we have no data or few data to hide the scrollbar and the reduce the scroll size
	const widgetClasses = () => {
		return classNames(
			{
				"leg-fuel-scroll-consumption":
					props.chartData.series[0].data.length ||
					props.chartData.series[1].data.length
			},
			{
				"leg-fuel-consumption":
					!props.chartData.series[0].data.length &&
					!props.chartData.series[1].data.length
			},        
		);
	};
	const widgetSubClasses = () => {
		return classNames(
			{
				"inner-barchart-scroll-container":
					(props.chartData.series[0].data.length ||
					props.chartData.series[1].data.length)
			},
			{
				"inner-barchart-container":
					!props.chartData.series[0].data.length &&
					!props.chartData.series[1].data.length
			},
            
		);
	};

    return (
        <div className={widgetClasses()}>
            <div className={widgetSubClasses()}>
                <HighchartsReact options={props.chartData} highcharts={Highcharts} />
            </div>
        </div>

    );
}
 
export default LegFuelConsumption;
