import React from 'react';
import ReportsSelectNavigation from 'reports/reportsSelectNavigation';
import Datepicker from 'components/reactDates/reactDates';
import ReportsGoToDates from './reportsGoToDates';
import ReportSourceLegend from "./reportSourceLegend";
import ReportPeriod from "./reportPeriod";

const ReportsHeader = (props) => {
    const changeDatesRange = (startDate, endDate) => {
        props.updateFromTo({from: startDate, to: endDate});
    }

    return (
        <div className="reports__header ">
            <ReportsSelectNavigation />
            <div className="reports__header__tools d-flex justify-content-end align-items-start flex-nowrap marg-b-15">
                {props.hasData && (
                    <div className="d-flex full-width flex-wrap report-legends">
                        <ReportSourceLegend sourceTypes={props.sourceTypes} />
                        <ReportPeriod periods={props.periods} />
                    </div>
                )}
                <div className="d-flex justify-content-between align-items-center report-date-picker">
                    <Datepicker changeDatesRange={changeDatesRange} {...props} />
                    <ReportsGoToDates {...props} />
                </div>
            </div>
            {props.legsPeriodChip?.map((Comp, index) => (
                <div
                    className="legs-period-chips"
                    key={index}
                    style={{ visibility: props.showLegsPeriodChip ? "visible" : "hidden" }}
                >
                    {Comp}
                </div>
            ))}
        </div>
    );
}
 
export default ReportsHeader;
