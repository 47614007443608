import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import L from 'leaflet';
import moment from 'moment';
import MapUtilities from '../../Utilities/map';
import service from "common/js/service";
import VesselRoot from './vesselRoot';
import Tooltip from 'components/muiHTMLTooltip/muiTooltip';
import rippleEffect from 'common/js/ripple';
import {ReactComponent as WindIcon} from 'assets/img/app/map-imgs/display-wind.svg';
import {ReactComponent as CurrentsIcon} from 'assets/img/app/map-imgs/display-currents.svg';
import {ReactComponent as EcaIcon} from 'assets/img/app/map-imgs/display-eco.svg';
import {ReactComponent as PiracyIcon} from 'assets/img/app/map-imgs/display-piracy-zones.svg';
import bulletCircleMarker from './bulletCircleMarker';
import ThemeColors from 'sass/_themeColorsForJs.scss';
import {userStore, vesselGroupStore, vesselStore, alertsStore, vesselGroupUtils, monitoringUtils, userUtils, vesselUtils, dashboardUtils, alertsUtils, monitoringStore} from 'common/store/storeUtils';
import {useDispatch, useSelector} from 'react-redux';

let fleetsColorsMapper = {}, allFleetsMapper = {}, localYearPicker = moment().year(), weatherDateTime = null, isDateTimeUpdated = true, previousDatetime = 0;
const granularityShortPeriods = [360,180,60,30];
const granularityLongPeriods = [1440,720,360,180];

let map = null, markers = [], markersLayers = [], previousZoom = null, objOfMaps = {}, objOfMarkers = {},
zoomChangedFromReq = false, longPeriod = false,
allVesselsData = undefined, boundsMovingMap = false, legWithAnchor = true,
lastMarkersPopups = [], weatherLayers = [], layerControl = null,
ecaLayer = null, piracyLayer = null, circleAroundMarker = null, isMounted=false,
mapFromTo = {
    from: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
    to: moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).valueOf()
}, mapAlertInspection = {}

const mapElement = React.createRef();
const windBtn = React.createRef();
const currentsBtn = React.createRef();
const ecaZonesBtn = React.createRef();
const piracyZonesBtn = React.createRef();

const Map = (props) => {

    // store vars
    const dispatch =  useDispatch();
    const vesselGroupStoreJSX = useSelector(state => state.vesselGroup);
    const vesselStoreJSX = useSelector(state => state.vessel);
    const alertsStoreJSX = useSelector(state => state.alerts);
    const selectedPickerYear = useSelector(state => state.monitoring.ciiSearchingYear.selectedPickerYear);

    const initMapLayers = () => {
        if(props.reports || props.inDashboard) return;
        weatherLayers = [];
        weatherLayers.push(MapUtilities.windLayer(userStore().user?.theme));
        weatherLayers.push(MapUtilities.currentsLayer(userStore().user?.theme));

        MapUtilities.getImportantZones('ECA_ZONE').then(response => {
            !response?.error && (ecaLayer = response?.data);
        });

        MapUtilities.getImportantZones('PIRACY_ZONE').then(response => {
            !response?.error && (piracyLayer = response?.data);
        });
    }

    const getZoneCurrent = (type) => {
        if (type==='eca') return ecaZonesBtn.current;
        return piracyZonesBtn.current;
    }

    const ecaPiracyToggler = type => {
        if(props.reports) return;
        const button = getZoneCurrent(type);
        const layer = (type === 'eca') ? ecaLayer : piracyLayer;
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;

        if(button.className.includes('active')) {
            button.classList.remove('active');
            layer !== null && referenceMap.removeLayer(layer);
        } else {
            button.classList.add('active');
            layer !== null && layer.addTo(referenceMap);
        }
    }

    const updateWeatherButtons = (type) => {
        const controlLayers = document.querySelector('.leaflet-control-layers-overlays');
        const windCheckbox = document.querySelectorAll('.leaflet-control-layers-selector')[1] || null;
        const currentsCheckbox = document.querySelectorAll('.leaflet-control-layers-selector')[2] || null;
        const windButton = windBtn.current;
        const currentsButton = currentsBtn.current;

        // The 'previous-layer' flag is used when either wind or currents is already selected, and the dates are changed from the UI.
        // This requires an early return to avoid removing the className of the selected layer and keep them active.
        if(!controlLayers || !windCheckbox || !currentsCheckbox || type === 'previous-layer') return;
        if(type === 'wind') {
            if(currentsCheckbox.checked) {
                currentsCheckbox.click();
                currentsButton.classList.remove('active');
            }
            if(windCheckbox.checked) {
                map.removeLayer(weatherLayers[0]);
            }
            windCheckbox.click();
            windButton.classList.toggle('active');
        } else {
            if(windCheckbox.checked) {
                windCheckbox.click();
                windButton.classList.remove('active');
            }
            if(currentsCheckbox.checked) {
                map.removeLayer(weatherLayers[1]);
            }
            currentsCheckbox.click();
            currentsButton.classList.toggle('active');
        }
    }

    // switches to off the weather buttons (currents and wind)
    const initWeatherButtons = () => {
        const controlLayers = document.querySelector('.leaflet-control-layers-overlays');
        const windCheckbox = document.querySelectorAll('.leaflet-control-layers-selector')[1] || null;
        const currentsCheckbox = document.querySelectorAll('.leaflet-control-layers-selector')[2] || null;
        const windButton = windBtn.current;
        const currentsButton = currentsBtn.current;

        // we need an early return if we are in Dashboards because we remove the actionButtons from the maps
        if(!controlLayers || !windCheckbox || !currentsCheckbox || props.inDashboard) return;
        
        if(currentsCheckbox.checked) {
            currentsCheckbox.click();
            currentsButton.classList.remove('active');
            map.removeLayer(weatherLayers[1]);
        }
        if(windCheckbox.checked) {
            windCheckbox.click();
            windButton.classList.remove('active');
            map.removeLayer(weatherLayers[0]);
        }
    }

    const weatherToggler = (type) => {
        if(props.reports) return;
        // if we have 3 layers instead of 1 it means that the requests have already been called so only the weather buttons are getting updated
        // the weather request is triggered only one time, on user's first click (on either the "currents" or "wind" button) OR when the needed date range changes
        if(((layerControl?._layers?.length === 1) || isDateTimeUpdated) && previousDatetime !== weatherDateTime) {
            // We need the previous datetime ('previousDatetime') because there are cases that if the user changes only the 'from' value in the datepicker,
            // there is no need to call the weather API since it uses the 'to' timestamp.
            // and a bug case when a user change date in cii with open weather-layer and close the button after the weather-request gets trigger again
            previousDatetime = weatherDateTime;
            service.getWeather((isDateTimeUpdated && weatherDateTime) ? weatherDateTime : null).then(
                result => {
                    if (result.error) return;
                    // setting leaflet map layers with the weather data
                    weatherLayers[0].setData(JSON.parse(result.wind));
                    // check if layer already exists to avoid duplicates
                    !layerControl?._layers.some(l => l.name === 'Wind') && layerControl.addOverlay(weatherLayers[0], 'Wind');
                    weatherLayers[1].setData(JSON.parse(result.current));
                    // check if layer already exists to avoid duplicates
                    !layerControl?._layers.some(l => l.name === 'Currents') && layerControl.addOverlay(weatherLayers[1], 'Currents');
                    updateWeatherButtons(type);
                }
            );
            isDateTimeUpdated = false;
        } else {
            updateWeatherButtons(type);
        }
    }

    const toggleWeatherRequestIfLayerButtonsActive = () => {
        // when we change dates from the datepicker in cii all fleets, cii fleet selected, or in the monitoring
        // and the weather options are active we need to call the weather request again to update the weather on map 
        const activeBtn = [windBtn, currentsBtn].find(btn => btn?.current?.className?.includes('active'));
        if (activeBtn) weatherToggler('previous-layer')
    }

    /**This function delete google map markers */
    const deleteMarkers = () => {
        if(!markers || markers.length === 0) return;
        if(lastMarkersPopups.length > 0) lastMarkersPopups.forEach(function(lp){lp.remove();});
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;

        lastMarkersPopups = [];
        referenceMap.eachLayer(layer => {
            if(layer instanceof L.Marker || layer instanceof L.CircleMarker)
                if(layer) referenceMap.removeLayer(layer);
        });
        markers.forEach(m => {
            if(referenceMap) referenceMap.removeLayer(m);
        });
        markers = [];
    }

    /**This function get the two edges of the vessel's root and sets the map bounds */
    const setRootBounds = (data) => {
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;

        referenceMap.closePopup();
        const bounds = new L.LatLngBounds(new L.LatLng(data.maxlat, data.minlon), new L.LatLng(data.minlat, data.maxlon));
        boundsMovingMap = true;
        referenceMap.fitBounds(bounds);
    }

    /**This function get the zoom and the markers of this zoom
    and push them to the markers layers object */
    const addNewMarkersLayer = (mapMarkers, granularity) => {
        const tempLayer = {'markers': Object.assign([], mapMarkers)};
        const granTypes = (!longPeriod) ? granularityShortPeriods : granularityLongPeriods;

        if(granularity === granTypes[0]) markersLayers[3] = tempLayer;
        if(granularity === granTypes[1]) markersLayers[1] = tempLayer;
        if(granularity ===  granTypes[2]) markersLayers[0] = tempLayer;
        if(granularity ===  granTypes[3]) markersLayers[2] = tempLayer;
    }

    /**This fuction get granularity and set the time period and
    send request to database to get the vessel data for this time period and this granularity */
    const getDataByGranularity = (granularity, notAddOnLayers) => {
        const referenceVessel = props.inDashboard ? props.widgetVesselIds[0] : vesselStore().selectedVessel;

        return service.getVesselDataHistory({
            vesselId: referenceVessel,
            startingDate: mapFromTo.from,
            endingDate: mapFromTo.to,
            granularity: granularity
        }).then(
            result => {
                if (result.error) return;
                if (notAddOnLayers) return result;
                addNewMarkersLayer(result, granularity);
            }
        );
    }

    const setParentsState = (data) => {
        if(data.length === 0 && !props.reports) {
            props.setVesselDataHistory(null);
            // monitoringUtils.resetLegView(dispatch);
            return;
        }

        if(!longPeriod) {
            var startTime = new Date().getTime();
            let waitVesselDataFillLayers = setInterval(() => {
                if(markersLayers[0]) {

                    if (!props.reports) {
                        // monitoringUtils.resetLegView(dispatch);
                        props.setVesselDataHistory(markersLayers[0].markers);
                    }

                    clearInterval(waitVesselDataFillLayers);
                    return;
                }
                if(new Date().getTime() - startTime > 10000){
                    clearInterval(waitVesselDataFillLayers);
                    return;
                }
            });
        } else {
            getDataByGranularity(60, true).then(
                result => {
                    if (!props.reports) {
                        props.setVesselDataHistory(result);
                        // monitoringUtils.resetLegView(dispatch);
                    }
                }
            );
        }
    }

    /**This function gets the markers layer and set the this layer of markers into map */
    const setLayer = (layer, granularity) => {
        deleteMarkers();
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;
        const referenceVesselId = props.inDashboard ? props.widgetVesselIds[0] : vesselUtils.getSelectedVesselData().vesselId;
        const referenceVesselName = props.inDashboard ? vesselUtils.getVesselName(props.widgetVesselIds[0]) : vesselUtils.getSelectedVesselData().name;

        if(!markersLayers[layer]) return;
        const data = markersLayers[layer].markers;

        const vesselColorNumber = allFleetsMapper[referenceVesselId];
        const vesselImageColored = 'fleetColor' + vesselColorNumber;
        previousZoom = referenceMap.getZoom();
        setTimeout(() => setParentsState(data));

        let vesselRootChanges = VesselRoot.renderVesselRootMarkersToMap(referenceVesselName, referenceMap,
        data, markers, lastMarkersPopups, legWithAnchor,
        markerClickedFromMap.bind(this), props.alwaysAnchor, props.reports, vesselImageColored,
            vesselColorNumber, objOfMarkers, props.widgetId);

        if (props.inDashboard) {
            objOfMarkers[props.widgetId] = vesselRootChanges.markers;
        } else {
            markers = vesselRootChanges.markers;
        }
        legWithAnchor = true;
    }

    /**This function gets current map zoom and matching zoom with layer */
    const getMarkersForCurrentZoom = (zoom) => {
        if(zoom <= 3 && previousZoom <= 3) return;
        if((zoom === 4 || zoom === 5) && (previousZoom === 4 || previousZoom === 5)) return;
        if((zoom === 6 || zoom === 7) && (previousZoom === 6 || previousZoom === 7)) return;
        if(zoom > 7 && previousZoom > 7) return;

        const granTypes = (longPeriod) ? granularityLongPeriods : granularityShortPeriods;
        if(zoom <= 3) return setLayer(3, granTypes[0]);
        if(zoom === 4 || zoom === 5) return setLayer(1, granTypes[1]);
        if(zoom === 6 || zoom === 7) return setLayer(0, granTypes[2]);
        return setLayer(2, granTypes[3]);
    }

    /**This function call function for caching next data */
    const cashingNextVesselData = (initLayer) => {
        const granTypes = (longPeriod) ? granularityLongPeriods : granularityShortPeriods;

        if(initLayer === 0) cashingData(granTypes[0], granTypes[1], granTypes[3]);
        if(initLayer === 1) cashingData(granTypes[0], granTypes[2], granTypes[3]);
        if(initLayer === 2) cashingData(granTypes[0], granTypes[1], granTypes[2]);
        if(initLayer === 3) cashingData(granTypes[1], granTypes[2], granTypes[3]);
    }

    /**This function gets the next layers for cashing*/
    const cashingData = (firstGranularity, secondGranularity, thirdGranularity) => {
        const referenceVessel = props.inDashboard ? props.widgetVesselIds[0] : vesselStore().selectedVessel;
        if (referenceVessel === vesselStore().allVesselsObj.vesselId) return;

        getDataByGranularity(firstGranularity)
            .then(getDataByGranularity(secondGranularity))
            .then(getDataByGranularity(thirdGranularity))
            .then(zoomChangedFromReq = false);
    }

    /**This function gets the init granularity to set the markers and then cashing
    markers data of all others layers */
    const fetchMarkers = (granularity, layer) => {
        if((vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) && !props.inDashboard) return;
        getDataByGranularity(granularity).then(() => {
            setLayer(layer, granularity);
            setTimeout(() => cashingNextVesselData(layer));
        });
    }

    const changeMapSize = (type) => {
        const mapEl = mapElement.current;
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;

        if(!mapEl) return;

        if(props.alerts) {
            mapEl.classList.add('map-widget-alerts-size');
            return referenceMap.invalidateSize();
        }
        if(props.reports) {
            mapEl.classList.add('map-widget-size');
            return referenceMap.invalidateSize();
        }
        // if(type === 'full') {
        //     mapEl.classList.remove('map-halfHeight');
        //     mapEl.classList.add('map-fullHeight');
        // } else {
        //     mapEl.classList.remove('map-fullHeight');
        //     mapEl.classList.add('map-halfHeight');
        // }
        referenceMap.invalidateSize();
    }

    const getDatesForWidget = () => {
        let tempDates;

        // Check if has already date - Leg case
        if (dashboardUtils.dashboardProperties.timespansObject[props.widgetData.widgetId+props.widgetData.id]?.timestampFrom) {
            tempDates = {
                from: dashboardUtils.dashboardProperties.timespansObject[props.widgetData.widgetId+props.widgetData.id]?.timestampFrom,
                to: dashboardUtils.dashboardProperties.timespansObject[props.widgetData.widgetId+props.widgetData.id]?.timestampTo
            };
        } else {
            tempDates = {
                from: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[props.widgetData.widgetId]).from,
                to: dashboardUtils.dashboardTimespans(dashboardUtils.dashboardProperties.timespansObject[props.widgetData.widgetId]).to
            }
        }

        return tempDates;
    }

    const loadVesselData = () => {
        if((vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) && !props.inDashboard) return;

        if(props.reports && mapFromTo.from === mapFromTo.to) return;
        if(alertsStore().alertInspection.status) return;
        if(alertsStore().activeAlert && Object.keys(alertsStore().activeAlert).length !== 0) return;
        if(props.reports) return fetchHistoryFromTo();
        const referenceVessel = props.inDashboard ? props.widgetVesselIds[0] : vesselStore().selectedVessel;

        longPeriod = false;

        service.getFirstLastPoints({vesselId: referenceVessel}).then(
            result => {
                if (result?.error || !isMounted) return;
                if (!props.inDashboard) {
                    mapFromTo = {from: result.from, to: result.to};
                } else {
                    mapFromTo = getDatesForWidget();
                }
                longPeriod = result.to - result.from > 2592000000;
                if (!props.reports) monitoringUtils.updateFromTo(dispatch, {from: result.from, to: result.to});
                setRootBounds(result);
                weatherDateTime = result.to;
                isDateTimeUpdated = true;

                // switches to off the weather buttons, so it's up to the user the request for weather data when a new vessel is selected
                // this prevents keeping weather data for a date of the previous selected vessel
                initWeatherButtons();
            }
        );
    }

    const fetchHistoryFromTo = () => {
        const referenceVessel = props.inDashboard ? props.widgetVesselIds[0] : vesselStore().selectedVessel;

        if((vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) && !props.inDashboard) return;
        if((props.reports && props.toDateIsToday) && !props.alerts && moment(props.fromTo.to).utc().format('DD') === moment().format('DD')) return;
        
        longPeriod = mapFromTo.to - mapFromTo.from > 2592000000;
        weatherDateTime = mapFromTo.to;
        isDateTimeUpdated = true;
        toggleWeatherRequestIfLayerButtonsActive();

        service.getFirstLastPoints({vesselId: referenceVessel, startingDate: mapFromTo.from, endingDate: mapFromTo.to}).then(
            result => result && !result.error && setRootBounds(result, false)
        );
    }

    const getMarkersForThisBounds = () => {
        if(!boundsMovingMap) return;
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;
        const zoom = referenceMap.getZoom();
        const granTypes = (!longPeriod) ? granularityShortPeriods : granularityLongPeriods;
        if(zoom <= 3) fetchMarkers(granTypes[0], 3);
        if(zoom > 3 && zoom <= 5) fetchMarkers(granTypes[1], 1);
        if(zoom === 6 || zoom === 7) fetchMarkers(granTypes[2], 0);
        if(zoom > 7) fetchMarkers(granTypes[3], 2);
        boundsMovingMap = false;
    };

    /** This function adding a circle marker around the leg marker clicked to highlighted it. */
    const addCircleAroundMarker = (marker, vesselColorNumber) => {
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;
        if(circleAroundMarker) referenceMap.removeLayer(circleAroundMarker);
        const fleetColor = `fleetColor${vesselColorNumber}`;
        let bulletCircleIcon = L.divIcon({
            iconSize: [20, 20],
            iconAnchor: [10, 10],
            className: 'bullet-circle-wrapper',
            html: bulletCircleMarker(ThemeColors[fleetColor])
        });

        circleAroundMarker = L.marker([marker._latlng.lat, marker._latlng.lng], {icon: bulletCircleIcon}).addTo(referenceMap);
        // circleAroundMarker.setZIndexOffset(100);
    }

    /** This function called when a round bullet of vessel leg on map clicked and update all monioring view
     * with this current time of marker that was clicked. */
    const markerClickedFromMap = (count, vd, vesselColorNumber) => {
        if(props.reports || props.inDashboard) return;

        addCircleAroundMarker(markers[count], vesselColorNumber);

        const dateTime = vd.datetime;
        const from = moment(vd.datetime).utc().subtract(30, 'minutes').valueOf();
        const to = moment(vd.datetime).utc().add(60, 'minutes').valueOf();
        const guidesStart = dateTime;
        const guidesEnd = moment(dateTime).utc().add(30, 'minutes').valueOf();

        !props.reports && props.setDetailedData({status: true, dateTime, from, to, guidesStart, guidesEnd});
    }

    const addRipppleEffect = () => {
        setTimeout(() => {
            document.getElementsByClassName('leaflet-control-zoom-in')[0] && document.getElementsByClassName('leaflet-control-zoom-in')[0].classList.add('ripple');
            document.getElementsByClassName('leaflet-control-zoom-out')[0] && document.getElementsByClassName('leaflet-control-zoom-out')[0].classList.add('ripple');
            rippleEffect.initRipple();
        });
    }

    const initMap = () => {
        const El = document.body;

        const styleUrl = (El.classList.contains('ds-white'))
            ? 'https://api.mapbox.com/styles/v1/nikosper/cjnd9ddj20ghn2ssy8zpk0wpd/tiles/256/{z}/{x}/{y}?access_token={accessToken}'
            : 'https://api.mapbox.com/styles/v1/nikosper/cjkcb436o0i7s2rrw3phc0mi2/tiles/256/{z}/{x}/{y}?access_token={accessToken}';

        const mapTheme = window.location.href.includes('deepsea.ai')
            ?   L.tileLayer(styleUrl, {
                    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors,'+
                    '<a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
                    accessToken: 'pk.eyJ1Ijoibmlrb3NwZXIiLCJhIjoiY2prY2N2ZDE3MDd4bzN3cW1xNGtvenBhayJ9.yNShCXLJXArR8LhtnrCOEA'
                })
            :   L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                });

        map = L.map(props?.mapContainerID ? props.mapContainerID : 'map-component', {
            minZoom: 2,
            maxZoom: 10,
            layers: [mapTheme],
            attributionControl: false,
            zoomControl: false,
            scrollWheelZoom: false,
            //worldCopyJump:true,
            maxBounds: L.latLngBounds([-90, -360], [90, 360])
        }).setView([15, 0], 2);

        L.control.zoom({
            position:'bottomright'
        }).addTo(map);

        const baseLayers = {
            "Dark theme": mapTheme
        };

        let overlays = {};

        layerControl = L.control.layers(baseLayers, overlays).addTo(map);

        map.on('moveend', () => {
            if((vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) && !props.inDashboard) return;
            if( !mapFromTo?.from || !mapFromTo?.to) return;
            getMarkersForThisBounds();
        });
        map.on('zoomend', () => {
            if(zoomChangedFromReq) return;
            let currentMapZoom = map.getZoom();
            if (markersLayers.length > 0 && vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel && !props.inDashboard)
                getMarkersForCurrentZoom(currentMapZoom);
        });

        objOfMaps[props.widgetId] = map;

        addRipppleEffect();
    }

    const createVesselMarker = (vessel, lastVesselPosition, markerIconColor, iconNumber, allFleets) => {
        if(!lastVesselPosition) return;
        if(lastVesselPosition.latitude != null && lastVesselPosition.longitude != null) {
            const popupClass = 'last-marker-popup marker-'+iconNumber;
            const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;
            const con = '<div class="'+popupClass+'">'+vessel.name+'</div>';
            const popup = L.popup({closeButton: false, autoClose: false, closeOnClick: false}).setContent(con);
            const dsIcon = L.divIcon({
                iconSize: [40,40],
                html: MapUtilities.vesselPulse(markerIconColor),
                popupAnchor: [10, -15],
                iconAnchor: [20, 35]
            });
            let marker = L.marker([lastVesselPosition.latitude, lastVesselPosition.longitude], {icon: dsIcon}).addTo(referenceMap);

            marker.vName = vessel.name;
            (allFleets) ? marker.bindPopup(popup)
            : marker.bindPopup(popup).openPopup();
            marker.on('click', e => {
                if (props.inDashboard) return;
                if(!props.reports) {
                    vesselGroupUtils.setSelectedAndChange(dispatch, vessel.vesselGroupId);
                }
                dispatch({ type: 'vessel/setSelectedVessel', payload: vessel.vesselId });
                alertsUtils.init(dispatch);
                if(props.reports) return props.history.push({
                    pathname:'/reports/dailyVesselReport',
                    // state: {pushFromFleetReport: true}
                });
                changeMapSize('half');
            });
            if(allFleets) {
                marker.on('mouseover', () => marker.openPopup() );
                marker.on('mouseout', () => marker.closePopup() );
            }
            markers.push(marker);
        }
    }

    const initMapWithAllVessels = (allVesselsLocal) => {
        if(!allVesselsLocal?.data) return;
        if(vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel && !props.reports && (!props.inDashboard)) return;
        if(allVesselsLocal?.data) allVesselsData = allVesselsLocal.data;
        if(props.reports && vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id && !props.inDashboard) return;

        isDateTimeUpdated = true;
        if(vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id || props.widgetLevel === 'COMPANY') {
            vesselGroupStore().allVesselGroups.forEach((fleet, index) => {
                fleetsColorsMapper[fleet.id] = index > 15 ? index - 16 : index;
            });
        }

        allVesselsData.forEach((ves) => {
            let vesselObj = vesselUtils.getObjOfAVessel(ves.vesselId);
            let lastVesselPosition = {longitude: ves.longitude, latitude: ves.latitude};

            let iconNumber = fleetsColorsMapper[vesselObj.vesselGroupId];
            let iconNumberVessel = 0;

            if (props.inDashboard) {
                if (props.widgetLevel === 'COMPANY') {
                    return createVesselMarker(vesselObj, lastVesselPosition, 'fleetColor' + iconNumber, iconNumber, true);
                } else if (props.widgetLevel === 'GROUP') {

                    if (vesselObj.vesselGroupId === vesselGroupUtils.getVesselGroupIdFromVesselId(props.widgetVesselIds)) {
                        iconNumberVessel = allFleetsMapper[vesselObj.vesselId];
                        return createVesselMarker(vesselObj, lastVesselPosition, 'fleetColor' + iconNumberVessel, iconNumberVessel, false);
                    }
                }

            } else {
                if (vesselGroupStore().selectedVesselGroup !== vesselGroupStore().allVesselGroupsObj.id && vesselObj.vesselGroupId === vesselGroupStore().selectedVesselGroup) {
                    iconNumberVessel = allFleetsMapper[vesselObj.vesselId];
                    return createVesselMarker(vesselObj, lastVesselPosition, 'fleetColor' + iconNumberVessel, iconNumberVessel, false);
                }
                else if (vesselGroupStore().selectedVesselGroup === vesselGroupStore().allVesselGroupsObj.id)
                    createVesselMarker(vesselObj, lastVesselPosition, 'fleetColor' + iconNumber, iconNumber, true);
            }

        });
        // If the vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId it means we are on cii pages 
        // so there is a need to update the weatherDateTime with the ciiFromTo in order to compare it with the previousDatetime and call the request
        // we call the request for the weather here and not in the useEffect of the cii with the dependency (overallReportsFromToChanged)
        // because the monitoringStore().ciiFromTo.to is sync with the updated value here
        if (vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId){
            weatherDateTime = monitoringStore().ciiFromTo.to;
            toggleWeatherRequestIfLayerButtonsActive();
        }
    }

    const initFun = () => {
        if(map && !props.inDashboard) map.remove();
        // when we initialise the map we need also to initialize the previous dateTime
        // in order not to make any conflict in case the new timestamp for the new selected vessel or fleet
        // its the same as before
        previousDatetime = 0;

        initMap();
        const type = (vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel) || props.inDashboard ? 'half' : 'full';
        changeMapSize(type);

        if(mapAlertInspection && mapAlertInspection.status && !props.reports) return;

        if(!props.reports) {
            if( ((vesselStore().allVesselsObj.vesselId !== vesselStore().selectedVessel) &&
                !alertsStore().alertInspectionMode) || props.widgetLevel === 'VESSEL' ) return loadVesselData();

                userUtils.getCompanyVessels(props.timestamp).then(
                result => !result.error && initMapWithAllVessels(result)
            );
        }
    }

    const dateRangeChange = () => {
        if(vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId && !props.reports) return;
        deleteMarkers();
        markersLayers = [];
        zoomChangedFromReq = true;
        legWithAnchor = false;
        (vesselStore().selectedVessel !== vesselStore().allVesselsObj.vesselId) ? fetchHistoryFromTo() : initFun();
    }

    const vesselChanged = () => {
        if(props.alerts) return;
        if(alertsStore().alertInspection.status) return;
        if(alertsStore().activeAlert && Object.keys(alertsStore().activeAlert).length !== 0) return;
        if(!props.reports) changeMapSize('half');
        deleteMarkers();
        markersLayers = [];
        zoomChangedFromReq = true;
        loadVesselData();
    }

    const fleetChanged = () => {
        if(props.alerts) return;
        if(alertsStore().alertInspection.status) return;
        if(alertsStore().activeAlert && Object.keys(alertsStore().activeAlert).length !== 0) return;
        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;
        referenceMap.setView([15, 0], 2);
        if(!props.reports) changeMapSize('full');
        deleteMarkers();
        markersLayers = [];
        userUtils.getCompanyVessels(props.timestamp).then(
            result => !result.error && initMapWithAllVessels(result)
        );
        // switches to off the weather buttons, so it's up to the user the request for weather data when a new fleet is selected
        initWeatherButtons();
    }

    const createAllFleetsMapper = () => {
        vesselGroupStore().allVesselGroups.forEach((vesselGroup) => {
            let counter = 0;
            vesselStore().allVessels.forEach(vessel => {
                if(vessel.vesselGroupId === vesselGroup.id) {
                    allFleetsMapper[vessel.vesselId] = counter > 15 ? counter - 16 : counter;
                    counter++;
                }
            });
        });
    }

    //--------------------------------------------------- useEffects -----------------------------------------------------------------------------
    useEffect(() => {
        if (!isMounted) return;
        if (!props.resetLegView) return;

        const referenceMap = props.inDashboard ? objOfMaps[props.widgetId] : map;

        if (props.resetLegView) referenceMap.removeLayer(circleAroundMarker);
    }, [props.resetLegView, props.inDashboard, props.widgetId]);

    // useEffect(() => {
    //     if(!selectedVesselGroup) return;
    //     if (!isMounted) return;
    //     if(props.dailyGroupReport) return;
    //     if(props.alerts) return;
    //     setChangeVesselGroup(false);
    //     setTimeout(() => fleetChanged());
    //     // eslint-disable-next-line
    // }, [selectedVesselGroup]);

    useEffect(() => {
        if(!vesselGroupStore().selectedVesselGroup) return;
        if (!isMounted) return;
        if(props.dailyGroupReport) return;
        if(props.alerts) return;
        if(!vesselGroupStore().changeVesselGroup) return;
        
        dispatch({ type: 'vesselGroup/setChangeVesselGroup', payload: false});
        
        setTimeout(() => fleetChanged());
        // eslint-disable-next-line
    }, [vesselGroupStoreJSX.selectedVesselGroup]);

    useEffect(()=>{
        if(!vesselStore().selectedVessel) return;
        if (!isMounted) return;
        if (props.reports) return;
        if (vesselStore().selectedVessel === vesselStore().allVesselsObj.vesselId) return;
        setTimeout(() => vesselChanged());
        dispatch({ type: 'vessel/setChangeVessel', payload: false });
    // eslint-disable-next-line
    }, [vesselStoreJSX.selectedVessel]);

    useEffect(() => {
        isMounted = true;
        mapAlertInspection = {};
        if (!props.reports) mapFromTo = props.fromTo; // if the map is not in the reports page
        if (props.inDashboard) {
            mapFromTo = getDatesForWidget();
        }
        createAllFleetsMapper();
        initMapLayers();
        initFun();

        return () => {
            isMounted = false;
            mapFromTo = {
                from: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf(),
                to: moment().set({ hour: 23, minute: 59, second: 0, millisecond: 0 }).valueOf()
            };
            const currentAlertInspectionMode = alertsStore().alertInspectionMode;
            if(currentAlertInspectionMode && !props.reports) alertsUtils.init(dispatch);
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        // Check for alert!
        if(props.alerts && alertsStore().alertInspection.status) {
            if(mapAlertInspection?.activeAlert?.id === alertsStore().alertInspection?.activeAlert?.id) return;
            if(!alertsStore().alertInspection.activeAlert) return;
            mapAlertInspection = alertsStore().alertInspection;
            if (mapAlertInspection && mapAlertInspection.status) {
                mapFromTo = {from: mapAlertInspection.from, to: mapAlertInspection.to};
                dateRangeChange();
            }
        }
    // eslint-disable-next-line
    },[alertsStoreJSX.alertInspection]);

    useEffect(() => {
        if(!isMounted) return;
        if (mapAlertInspection && mapAlertInspection.status) return;
        if(!props?.fromTo?.from) return;
        if(props.alerts) return;
        if(props.inDashboard) return;
        if(props.reports && !props.alerts && !props.canInitMap) return;

        if (props.fromTo.from !== mapFromTo.from || props.fromTo.to !== mapFromTo.to) {
            if(props.reports && !props.alerts && moment(props.fromTo.from).utc().format('DD-MM HH') === moment(props.fromTo.to).utc().format('DD-MM HH')) return;
            mapFromTo = {from: props.fromTo.from, to: props.fromTo.to};

            //check for invalid selected periods. (From after to date and if is more than 3 months)
            const invalidPeriod = mapFromTo.to - mapFromTo.from;
            if(invalidPeriod <= 0) return alert("Invalid period! End date must be bigger than start date");
            if(invalidPeriod > 7862400000) return alert( "Invalid period! Dates duration must be smaller than 3 months" );

            dateRangeChange();
        }

        if(props.dailyGroupReport) {
            userUtils.getCompanyVesselsGroupReport().then(
                result => {
                    if(!result.error) {
                        deleteMarkers();
                        initMapWithAllVessels(result);
                    }
                }
            );
        }
        // eslint-disable-next-line
    }, [props.fromToChanged]);

    useEffect(() => {
        if(!props.overallReportsFromToChanged) return;
        if(localYearPicker !== selectedPickerYear){
            deleteMarkers()
            userUtils.getCompanyVessels(props.timestamp).then(result => !result.error && initMapWithAllVessels(result));
            localYearPicker = selectedPickerYear
        }
        // eslint-disable-next-line
    }, [props.overallReportsFromToChanged]);

    //--------------------------------------------------- useEffects -----------------------------------------------------------------------------
    
    return (
        <div className="map-container">
            {(!props.reports && !props.inDashboard) && (
            <React.Fragment>
                <Tooltip title="WIND" component={<div className="map-button svg-path wind-button"
                ref={windBtn} onClick={() => weatherToggler('wind')}>
                    <div className="ico flex-centered"><WindIcon /></div>
                </div>} />
                <Tooltip title="CURRENTS" component={<div className="map-button svg-path currents-button"
                ref={currentsBtn} onClick={() => weatherToggler('currents')}>
                    <div className="ico flex-centered"><CurrentsIcon /></div>
                </div>} />
                <Tooltip title="ECA ZONES" component={<div className="map-button svg-path eca-zones-button"
                ref={ecaZonesBtn} onClick={() => ecaPiracyToggler('eca')}>
                    <div className="ico flex-centered"><EcaIcon /></div>
                </div>} />
                <Tooltip title="PIRACY ZONES" component={<div className="map-button svg-path piracy-zones-button"
                ref={piracyZonesBtn} onClick={() => ecaPiracyToggler('piracy')}>
                    <div className="ico flex-centered"><PiracyIcon /></div>
                </div>} />
            </React.Fragment>)}
            <div 
                id={props?.mapContainerID ? props.mapContainerID : 'map-component'} 
                ref={mapElement} 
                className={`map-halfHeight custom-popup ${props.mapClass ? props.mapClass : ''}`}/>
        </div>
    );
}

export default withRouter(Map);
