import request from "common/js/request";
import Storage from 'common/js/Storage';
import { Urls } from 'common/js/urls';
import { userStore } from 'common/store/storeUtils';

const getUrl = () => {
  return Urls.ServicesUrl()
};

const service = {
  //cii services
  getEfficiencyLegs: async ({from, to, vesselID, page}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/cii/leg-efficiency-list?vesselId=${vesselID}&datefrom=${from}&dateto=${to}&page=${page}`)
  },
  getVesselEfficiency: async ({from, to, vesselID, granularity}) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/cii/vessel-efficiency?vesselId=${vesselID}&dateFrom=${from}&dateTo=${to}&granularity=${granularity}`
    );
  },
  getGroupEfficiency: async ({from, to, groupID, granularity}) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/cii/fleet-efficiency?dateFrom=${from}&dateTo=${to}&granularity=${granularity}&groupId=${groupID}`
    );
  },
  getCompanyEfficiency: async ({from, to, granularity}) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/cii/company-efficiency?dateFrom=${from}&dateTo=${to}&granularity=${granularity}`
    );
  },
  getCiiGroupsOverview: async ({from, to}) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/cii/fleet-list?dateFrom=${from}&dateTo=${to}`
    );
  },
  getCiiVesselOverview: async ({from, to, vesselID}) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/cii/vessel-rating?vesselId=${vesselID}&dateFrom=${from}&dateTo=${to}`
    );
  },
  getCiiFleetOverview: async ({from, to, groupID}) => {
    return await request.get(
      `${getUrl().BaseURL}/vessel-data/cii/fleet-overview?fleetId=${groupID}&datefrom=${from}&dateto=${to}`
    ).then(res => res.data);
  },
  getVesselPeriod: async ({from, to, id}) => {
    return await request.get(
      `${getUrl().BaseURL}/vessel-data/cii/vesselPeriod?vesselId=${id}&dateFrom=${from}&dateTo=${to}`
    ).then(res => res.data);
  },
  getFleetPeriod: async ({from, to, id}) => {
    return await request.get(
      `${getUrl().BaseURL}/vessel-data/cii/fleetPeriod?fleetId=${id}&dateFrom=${from}&dateTo=${to}`
    ).then(res => res.data);
  },
  getCompanyPeriod: async ({from, to}) => {
    return await request.get(
      `${getUrl().BaseURL}/vessel-data/cii/companyPeriod?&dateFrom=${from}&dateTo=${to}`
    ).then(res => res.data);
  },
  getCiiCompanyOverview: async ({from, to}) => {
    return await request.get(
      `${getUrl().BaseURL}/vessel-data/cii/company-overview?&datefrom=${from}&dateto=${to}`
    ).then(res => res.data);
  },
  getCiiGroupOverview: async ({from, to, groupID}) => {
    return await request.get(
      `${getUrl().BaseURL}/vessel-data/cii/vessel-list?dateFrom=${from}&dateTo=${to}&groupId=${groupID}`
    ).then(res => res.data);
  },

  //reports

  getReportPeriods: (id, from) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/reports/getReportPeriods?vesselIds=${id}&datetime=${from}`
    );
  },
  getLegReportPeriod: (id, from, to) => {
    return request.get(
        `${getUrl().BaseURL}/vessel-data/reports/getLegReportPeriod?vesselId=${id}&dateFrom=${from}&dateTo=${to}`
    );
  },
  getLegs: (id, from, to) => {
    return request.get(
      `${getUrl().BaseURL}/vessel-data/legs?vesselId=${id}&dateFrom=${from}&dateTo=${to}`
    );
  },
  getReports: (url, body, action) => {
    return request.post(`${getUrl().BaseURL}/vessel-data${url}`, body, {headers: {'action': action}});
  },
  getPredictedPowerPenalty: async ({vessel, date}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/predictions/power-penalty/last?vesselId=${vessel}&date=${date}&type=actual`);
  },

  getPredictedPowerPenaltyOvertime: async ({ vessel }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/predictions/power-penalty/series?vesselId=${vessel}`).then(res => res?.data);
  },

  // terms
  acceptTerms: ({ date, userId, token }) => {
    const payload = {
        acceptanceDate: date,
        accepted: true
    };
    return request.patch(`${getUrl().BaseURL}/user-management/users/${userId}/tac`, payload);
  },
  getUserTermsInfo: ({ userId, token }) => {
    return request.get(`${getUrl().BaseURL}/user-management/users/${userId}/tac`);
  },
  getCurrentTerms: () => {
    return request.get(`${getUrl().BaseURL}/deepsea-commons/terms-and-conditions/current`).then(r => r.data);
  },
  getFutureTerms: () => {
    return request.get(`${getUrl().BaseURL}/deepsea-commons/terms-and-conditions/new`);
  },
  getCurrentsTermsEffectiveDate: () => {
    return request.get(`${getUrl().BaseURL}/deepsea-commons/terms-and-conditions/current/effectiveDate`).then(r => r.data);
  },
  getFutureTermsEffectiveDate: () => {
    return request.get(`${getUrl().BaseURL}/deepsea-commons/terms-and-conditions/new/effectiveDate`).then(r => r.data);
  },

  // user
  loginUser: ({ username, password }) => {
    return request.post(
      `${getUrl().BaseURL}/user-management/users/login`,
      { username, password },
      { autoHandleError: false }
    );
  },
  logoutUser: ({ enableLoader }) => {
    return request.post(`${getUrl().LoginURL}/user-management/users/logout`, { access_token: Storage.getCookie('token') });
  },
  getUserData: () => {
    return request.get(
      `${getUrl().BaseURL}/operator/user/${Storage.getCookie('userId')}`
    );
  },
  getCompanyVesselsGroups: product => {
    return request.get(
      `${getUrl().BaseURL}/operator/user/${Storage.getCookie('userId')}/company/${product}`
    )
  },
  requestResetPass: ({username}) => {
    return request.post(
      `${getUrl().BaseURL}/user-management/users/password/reset`,
      {username}
    )
  },
  codeConfirmation: ({ username, confirmationCode }) => {
    return request.get(
      `${getUrl().BaseURL}/user-management/users/${username}/confirmation/${confirmationCode}`
    );
  },
  resetPassword: ({username, password1, password2, confirmationCode}) => {
    return request.post(
      `${getUrl().BaseURL}/user-management/users/password`,
      {username, password1, password2, confirmationCode}
    )
  },
  requestNewLink: ({username}) => {
    return request.post(
      `${getUrl().BaseURL}/user-management/users/${username}/confirmation/resend`,
      {username}
    )
  },

  changeTheme: (data) => {
    return request.patch(`${getUrl().BaseURL}/user-management/users/${userStore().user.uuid}/preferences`, data);
  },
  changePassword: (data) => {
    return request.patch(`${getUrl().BaseURL}/user-management/users/${userStore().user.uuid}/password`, data);
  },


  /**
   * enum type = [
   *      'updateUserFleetAssignment', 'toggleUserType',  // {enableLoader:false}
   *      'toggleUserStatus', 'newFleetUser', 'updateProfile'
   * ]
   */
  updateProfile: (data) => {
    return request.patch(`${getUrl().BaseURL}/user-management/users/${userStore().user.uuid}`, data);
  },
  // getFleetAssignments: ({ companyID }) => {
  //   return request.get(
  //     `${getUrl().BaseURL}/diPlatformBackend/api/fetch/adminInfoFleetAssignments:${companyID}`
  //   );
  // },
  getCompanyVessels: ({ companyID, timeStamp }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/points/latest-position?companyId=${companyID}&datetime=${timeStamp}`);
  },
  getCompanyVesselsGroupReport: ({ companyID, timeStamp, vesselGroupId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/points/daily-latest-position?companyId=${companyID}&groupId=${vesselGroupId}&datetime=${timeStamp}`);
  },

  // fleets
  /**
   * enum type = ['newFleet', 'updateFleet', 'deleteFleet']
   * */
  // fleetAction: ({ fleet, type }) => {
  //   return request.post(`${getUrl().BaseURL}/diPlatformBackend/api/store/${type}`, fleet);
  // },

  getCleaningEvents: ({ vesselId, datetime }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/events?&vesselId=${vesselId}&datetime=${datetime}&type=VESSEL_MAINTENANCE`,
    {headers: {'action': 'findLastByTypeAndDateAndVesselId'}}).then(response => response.data);
  },
  getGeInfo: (companyId) => {
    return request.get(`${getUrl().BaseURL}/operator/ge-info/company?companyId=${companyId}`).then(response => response.data);
  },
  getRunningTime: ({vesselId, from, to}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/generator-usage/total?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}`).then(response => response.data);
  },
  getRunningTimeSensoredAndTelegram: ({vesselId, from, to}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/generator-usage/total-sensors-telegrams?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}`).then(response => response?.data);
  },
  getScatterPlots: ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId, weatherConditionId, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/power-speed?vesselCode=${vesselId}&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}&weatherCondition=${weatherConditionId}&seaTrialType=${seaTrialType}`).then(response => response?.data);
    // return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/power-speed?vesselCode=${vesselId}&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(response => response?.data);
  },
  getFuelSpeedScatterPlots: ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId, weatherConditionId, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/fuel-speed?vesselCode=${vesselId}&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}&weatherCondition=${weatherConditionId}&seaTrialType=${seaTrialType}`).then(response => response?.data);
    // return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/fuel-speed?vesselCode=${vesselId}&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(response => response?.data);
  },
  getVesselDataHistory: ({
    vesselId,
    startingDate,
    endingDate,
    granularity
  }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/vessel-history?vesselId=${vesselId}&dateFrom=${startingDate}&dateTo=${endingDate}&granularity=${granularity}`) 
                  .then(response => response.data);
  },

  // events
  /**
   * enum type = ['groupTimelineEvents', 'timelineEvents']
   * */
  getGroupDailyTimelineEvents: ({ value, datetime}) => {
    return request.get(
        `${getUrl().BaseURL}/vessel-data/timeline/groupDailyTimelineEvents?groupId=${value}&datetime=${datetime}`
    ).then(response => response.data);
  },
  getVesselEvents: ({ value, startingDate, endingDate}) => {
    return request.get(
        `${getUrl().BaseURL}/vessel-data/timeline/timelineEvents?vesselIds=${value}&dateFrom=${startingDate}&dateTo=${endingDate}`
    ).then(response => response.data);
  },
  getVesselGroupEvents: ({ value, startingDate, endingDate }) => {
    return request.get(
        `${getUrl().BaseURL}/vessel-data/timeline/groupTimelineEvents?groupId=${value}&dateFrom=${startingDate}&dateTo=${endingDate}`
    ).then(response => response.data);
  },
  createEvent: ({ event }) => {
    return request.post(`${getUrl().BaseURL}/vessel-data/events`, { vesselEvents: [event] }).then(response => response.data);
  },
  updateEvent: ({ event }) => {
    return request.put(`${getUrl().BaseURL}/vessel-data/events`, { vesselEvents: [event] }).then(response => response.data);
  },
  deleteEvent: ({ event }) => {
    return request.delete(`${getUrl().BaseURL}/vessel-data/events`, { vesselEvents: [event] }).then(response => response.data);
  },
  getPorts: (value) => {
    return request.get(`${getUrl().BaseURL}/charts/ports?name=${value}`).then(response => ((!response || response.error) ? [] : response.data ))
  },

  // telegrams
  /**
   * enum telegramType = ['ATELEGRAM', 'DTELEGRAM', 'NTELEGRAM', 'OTELEGRAM']
   * */
  createTelegram: (telegram) => {
    return request.post(`${getUrl().BaseURL}/vessel-data/timeline/telegrams`,  [telegram.event] ).then(response => response.data);
  },
  updateTelegram: (telegram) => {
    return request.put(`${getUrl().BaseURL}/vessel-data/timeline/telegrams`, [telegram.event]).then(response => response.data);
  },
  deleteTelegram: (telegram) => {
    return request.delete(`${getUrl().BaseURL}/vessel-data/timeline/telegrams`, [telegram.event]).then(response => response.data);
  },

  // Alerts
  /**
   * enum region = ['fleet', 'vessel']
   * */
  getAlerts: ({ count, datetime, vessels }) => {
    let url = `${getUrl().BaseURL}/alert-web/notification?userId=${Storage.getCookie('user').uuid}&datetime=${datetime}&count=${count}&vesselIds=${vessels ? vessels : []}`;
    return request.get(url);
  },
  getAlertSensorData: async (body) => {
    return await service.getReports('/reports', body, 'get-highchart-report');
  },
  getAlertAnomalies: ({ vesselId, from, to, type, timeUnit }) => {
    const url = `${getUrl().BaseURL}/alert-web/anomaly?vesselId=${vesselId}&fromDate=${from}&toDate=${to}&alertType=${type}&timeUnit=${timeUnit}`
    return request.get(url);
  },
  getAnomaliesForMapBullets: ({ vesselId, from, to, granularity, type, timeUnit }) => {
    const url = `${getUrl().BaseURL}/alert-web/anomaly?vesselId=${vesselId}&fromDate=${from}&toDate=${to}&granularity=${granularity}&alertType=${type}&timeUnit=${timeUnit}`
    return request.get(url);
  },
  updateNotification: ({ alertId, userId }) => {
    const url = `${getUrl().BaseURL}/alert-web/notification?userId=${userId}&alertId=${alertId}`
    return request.get(url);
  },
  getAlertFuel: ({ vesselId, fromDate, toDate }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/fuel-waste?vesselId=${vesselId}&dateFrom=${fromDate}&dateTo=${toDate}`);
  },
  getAlertPistons: ({ vesselId, fromDate, toDate }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/alert-pistons/vessel?vesselId=${vesselId}&fromDate=${fromDate}&toDate=${toDate}`);
  },

  //weather requests ----------------------------------------------------

  getWeather: (timestamp) => {
    // we handle both cases where timestamp exists or not. If not, the queryParams is empty and the weather for now is requested
    const baseUrl = `${getUrl().BaseURL}/weatherService/api/v1/global/hindcasts/map`;
    const queryParams = timestamp ? `?datetime=${timestamp}` : '';

    return request.get(`${baseUrl}${queryParams}`);
  },

  //vesselData requests ---------------
  getFirstLastPoints: ({
    vesselId,
    startingDate = null,
    endingDate = null
  }) => {
    let url = `${getUrl().BaseURL}/vessel-data/points/first-last?vesselId=${vesselId}`;
    if (startingDate !== null && endingDate !== null) {
      url += `&dateFrom=${startingDate}&dateTo=${endingDate}`;
    }
    return request.get(url).then(response => response?.data);
  },
  /**
   * enum zone = ['ECA_ZONE', 'PIRACY_ZONE']
   * */
  getImportantZones: ({ zone }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/emergencySegment/${zone}`);
  },
  getMeInfo: ({ vesselId }) => {
    return request.get( `${getUrl().BaseURL}/operator/me-info?vesselId=${vesselId}`).then(response => response.data);
  },
  getHullData: ({ vesselId }) => {
    return request.get( `${getUrl().BaseURL}/operator/hull-info?vesselId=${vesselId}`).then(response => response.data);
  },
  getPvData: ({ vesselId, from, to, weatherConditionId, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/pv-chart?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&weatherCondition=${weatherConditionId}&seaTrialType=${seaTrialType}`).then(response => response.data);
    // return request.get(`${getUrl().BaseURL}/vessel-data/pv-chart?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&weatherCondition=DEFAULT`).then(response => response.data);
  },

  getFuelSpeedData: ({ vesselId, from, to, weatherConditionId, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/fv-curve?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&weatherCondition=${weatherConditionId}&seaTrialType=${seaTrialType}`).then(response => response?.data);
  },

  getCiiSpeedData: ({ vesselId, from, to, weatherConditionId, seaTrialType, fuelOilType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/cs-curve?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&weatherCondition=${weatherConditionId}&seaTrialType=${seaTrialType}${fuelOilType ? `&fuelType=${fuelOilType}` : ''}`).then(response => response?.data);
  },

  fuelSpeedHasData: ({ vesselId, from, to, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/is-fv-eligible?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&seaTrialType=${seaTrialType}`).then(res => res?.data);
  },

  getDraftModeOptions: ({vesselId, from, to}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/sea-trial/have-data?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}`).then(res => res?.data);
  },

  getWeatherConditionOptions: ({vesselId, from, to, seaTrialType}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/weather-condition/have-data?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&seaTrialType=${seaTrialType}`).then(res => res?.data);
  },

  getPowerSpeedTableData: ({ vesselId, from, to, weatherConditionIds, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/pv-table?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&weatherConditionCodes=${weatherConditionIds}&seaTrialType=${seaTrialType}`).then(res => res?.data);
  },

  getFuelSpeedTableData: ({ vesselId, from, to, weatherConditionIds, seaTrialType }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/fv-table?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}&weatherConditionCodes=${weatherConditionIds}&seaTrialType=${seaTrialType}`).then(res => res?.data);
  },

  // Daily Reports
  getTelegramsConfig: ({ vesselIds }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/telegrams/telegramConfigurationInfo?vesselIds=${vesselIds}`);
  },
  getTelegramsMonthlyDates: ({ vesselIds, datetime }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/telegrams/findMonthly/timestamps?vesselIds=${vesselIds}&datetime=${datetime}`);
  },
  getTelegramsDailyDates: ({ vesselIds, datetime }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/telegrams/findDaily?vessels=${vesselIds}&datetime=${datetime}`);
  },

  // Dynamic Dashboards
  getWidgets: (id) => {
    // return request.get('http://localhost:3005/initialWidgets');
    return request.get(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates/${id}`).then(res => res?.data);
  },
  getWidgetLibrary: async () => {
    return request.get(`${getUrl().BaseURL}/deepsea-commons/widgets/${Storage.getCookie('userId')}`).then(res => res?.data);
  },
  saveUnpublished: async (id, body) => {
    return request.requestFullResponse(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates/${id}/widgets`, 'PUT', body);
  },
  savePublished: async (id) => {
    return request.requestFullResponse(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates/${id}/publish`, 'POST', {});
  },
  getDashboardTemplates: async () => {
    return request.get(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates`).then(res => res.data);
  },
  createDashboardTemplate: async (title) => {
    return request.requestFullResponse(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates`, 'POST', {'title': title});
  },
  getDashboardCreatedTemplate: async (locationUrl) => {
    return request.get(`${getUrl().BaseURL}/user-management${locationUrl}`).then(res => res.data);
  },
  renameDashboardTemplate: async (newTitle, id) => {
    return request.requestFullResponse(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates/${id}`, 'PATCH', {"title": newTitle});
  },
  duplicateDashboardTemplate: async (sameTitleWithCopy, id) => {
    return request.requestFullResponse(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates/${id}/copy`, 'POST', {"title": sameTitleWithCopy});
  },
  getDashboardDuplicatedTemplate: async (locationUrl) => {
    return request.get(`${getUrl().BaseURL}/user-management${locationUrl}`).then(res => res.data);
  },
  deleteDashboardTemplate: async (id) => {
    return request.requestFullResponse(`${getUrl().BaseURL}/user-management/dashboards/users/${Storage.getCookie('userId')}/templates/${id}`, 'DELETE', {});
  },
  getLegsTimespanFromTo: async (vesselId) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/telegrams/findActiveTripIfExists?vesselId=${vesselId}`).then(res => res?.data);
  },
  getBoilerTypes: async (vesselId) => {
    return request.get(`${getUrl().BaseURL}/operator/boiler-info?vesselId=${vesselId}`).then(res => res?.data);
  },
  getBoilersRunningTime: ({vesselId, from, to}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/boiler-usage/total?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}`).then(response => response.data);
  },
  getFuelWasteAmount: ({vesselId, from, to}) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/predictions/power-penalty/waste?vesselId=${vesselId}&dateFrom=${from}&dateTo=${to}`).then(response => response.data);
  },
  getFuelWasteChart: ({payload, vesselId, fromDate, toDate}) => {
    return request.post(
        `${getUrl().BaseURL}/vessel-data/reports/predictions/highcharts`,
        {...payload, vesselId, fromDate, toDate}
    ).then(response => response.data);
  },
  getSFOCPowerCurvesData: async ({ vesselId }) => {
    return request.get(`${getUrl().BaseURL}/operator/me-info/reference-lines?vesselId=${vesselId}&metrics=SFOC_POWER`).then(res => res?.data);
  },
  getSFOCPowerScatterPlotsData: async ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/me-scatter-plots?vesselId=${vesselId}&metrics=SFOC_POWER&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(res => res?.data);
  },
  getFuelPowerCurvesData: async ({ vesselId }) => {
    return request.get(`${getUrl().BaseURL}/operator/me-info/reference-lines?vesselId=${vesselId}&metrics=FUELCONS_POWER`).then(res => res?.data);
  },
  getFuelPowerScatterPlotsData: async ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/me-scatter-plots?vesselId=${vesselId}&metrics=FUELCONS_POWER&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(res => res?.data);
  },
  getPowerRpmCurvesData: async ({ vesselId }) => {
    return request.get(`${getUrl().BaseURL}/operator/me-info/reference-lines?vesselId=${vesselId}&metrics=POWER_RPM`).then(res => res?.data);
  },
  getPowerRpmScatterPlotsData: async ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/me-scatter-plots?vesselId=${vesselId}&metrics=POWER_RPM&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(res => res?.data);
  },
  getSCAVPowerScatterPlotsData: async ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/me-scatter-plots?vesselId=${vesselId}&metrics=PSCAV_POWER&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(res => res?.data);
  },
  getTCRpmPowerCurvesData: async ({ vesselId }) => {
    return request.get(`${getUrl().BaseURL}/operator/me-info/reference-lines?vesselId=${vesselId}&metrics=TCRPM_POWER`).then(res => res?.data);
  },
  getTCRpmPowerScatterPlotsData: async ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/me-scatter-plots?vesselId=${vesselId}&metrics=TCRPM_POWER&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(res => res?.data);
  },
  getTCScavRpmScatterPlotsData: async ({ vesselId, from, to, scatterPlotCount, dashboardTemplateId }) => {
    return request.get(`${getUrl().BaseURL}/vessel-data/scatterPlots/me-scatter-plots?vesselId=${vesselId}&metrics=TCPSCAV_RPM&dateFrom=${from}&dateTo=${to}&scatterPlotCount=${scatterPlotCount}&dashboardTemplateId=${dashboardTemplateId}`).then(res => res?.data);
  },
};

export default service;
