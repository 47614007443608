import React from 'react';

const Legend = ({ type }) => {
    return (
        <div className="legend">
            <div className={`legend__dot legend__dot-${type}`} />
            <span className="legend__text">{type.toUpperCase()}</span>
        </div>
    );
}

const ReportSourceLegend = ({ sourceTypes }) => {
    return <div className="report-source-legend">
        SOURCE LEGEND:
        <div className="report-source-legend__items">
            {
                sourceTypes?.map((type, key) => <Legend type={type} key={key} />)
            }
        </div>
    </div>
}

export default ReportSourceLegend;
