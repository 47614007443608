import service from 'common/js/service';
import { sortBy } from 'lodash';
import { licensing } from '../licensing';
import { reportsStore } from 'common/store/storeUtils';

export const sourceTypes = {
    sensor: 'sensor',
    ais: 'ais',
    report: 'report',
}

const reportsUtils = {
    // the updateFromTo function is used in all reports pages in order to perform 2 dispatches together
    updateFromTo: (dispatch, fromTo, fromToChanged) => {
        dispatch({type: 'reports/setFromTo', payload: fromTo});
        dispatch({type: 'reports/setFromToChanged', payload: !fromToChanged});
    },
    // the updateFromToAndUserLeg function is used in leg report page and performs 2 dispatches together
    updateFromToAndUserLeg: (dispatch, fromTo, setUserLeg) => {
        dispatch({type: 'reports/setFromTo', payload: fromTo});
        dispatch({type: 'reports/setUserLeg', payload: setUserLeg});
    },
    async getTelegramsConfig({vesselIds}) {
        return service.getTelegramsConfig({vesselIds: vesselIds.join(',')});
    },
    async getTelegramsMonthlyDates({ vesselIds, datetime }) {
        return service.getTelegramsMonthlyDates({ vesselIds: vesselIds.join(','), datetime });
    },
    async getTelegramsDailyDates({ vesselIds, datetime }) {
        return service.getTelegramsDailyDates({ vesselIds: vesselIds.join(','), datetime });
    },

    getAvailableDailyGroupSourceTypes: () => {
        const availableSourceTypes = [];
        
        if (!licensing.fleetAllVesselsAreLight()) {
            availableSourceTypes.push(sourceTypes.sensor);
        }

        if (reportsStore().isTelegramConfigured) {
            availableSourceTypes.push(sourceTypes.report);
        }

        if (licensing.fleetLightCondition()) {
            availableSourceTypes.push(sourceTypes.ais);
        }

        return availableSourceTypes;
    },

    getAvailableVesselAndLegSourceTypes: () => {
        const availableSensors = [licensing.lightCondition() ? sourceTypes.ais : sourceTypes.sensor];

        if(reportsStore().isTelegramConfigured) {
            availableSensors.push(sourceTypes.report);
        }

        return availableSensors;
    },
    // since we get the periods from the api we have to make a re-ordering to keep the same order with the getAvailableDailyGroupSourceTypes
    getSortedDailyGroupPeriods: (periods) => {
        const typeOrder = ["SENSOR", "TELEGRAM", "AIS"];
        return sortBy(periods, (item) => typeOrder.indexOf(item.type.toUpperCase()));
    },
    // since we get the periods from the api we have to make a re-ordering to keep the same order with the getAvailableVesselAndLegSourceTypes
    getSortedVesselPeriods: (periods) => {
        const typeOrder = ["SENSOR", "AIS", "TELEGRAM"];
        return sortBy(periods, (item) => typeOrder.indexOf(item.type.toUpperCase()));
    },
}

export default reportsUtils;