import { ThemeColorsForCharts } from 'Utilities/themeColorsForCharts';
import horizontalStackBarChartOptions from 'components/charts/barChart/horizontalBarChart';
import moment from "moment";
import {licensing} from "../../common/store/licensing";
import { reportsStore, vesselUtils } from 'common/store/storeUtils';

const legFuelConsumptionPayload = {
    metrics:[
        {
            metricCategory: "MAIN_ENGINE",
            subIds: [1],
            metricData: [
                {
                    metricName: "foVolConsumptionAbsolute",
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {
                    metricName: "commonDGFOMassRateAbsolute",
                    aggregation: "SUM"
                },
                {
                    metricName: "boilerCommonFoMassRateAbsolute", 
                    aggregation: "SUM"
                }
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {
                    metricName: "me_1_foConsumption_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "ge_1_foConsumption_calc",
                    aggregation: "SUM"
                },
                {
                    metricName: "bo_1_foConsumption_calc", 
                    aggregation: "SUM"
                },
            ]
        }
    ],
    withMetadata: true
};

const updateLegFuelConsumption = (id, response, updateState, extraChartConfigs, widget) => {
    
    if((response && response?.series?.length === 0) || response.length === 0) return updateState(id, {}, widget?.id);
    
    // Initialize chart options
    let data = { ...horizontalStackBarChartOptions("mt") };
    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    const categories = [], reportsData = [], sensorsData = [];

    // creating mapper values for better readability
    const sensorMapper = response?.series[0]?.values[0]
    const reportsMapper = response?.series[0]?.values[1]
    // checking which data between sensors and telegrams is a bigger array or one of them does not exists in order to loop
    const mapper = (sensorMapper?.value?.length > reportsMapper?.value?.length ) || !reportsMapper?.value.length
        ? response.series[0].values[0] 
        : response.series[0].values[1];

    // use one loop to create categories, reports, and sensors data
    mapper?.value && mapper.value.forEach((point, index) => {
        categories.push(moment(point.x).utc().format('DD/MM'));
        if (mapper.metricCategory === 'TELEGRAM') {
            if (point && point.y !== null) {
                reportsData.push({ 
                    y: point.y,
                    options: {
                        ...point.telegramPeriod, 
                    } 
                });
            }
            const sensorValue = sensorMapper?.value[index];          
            if (sensorValue && sensorValue.y !== null) {
                sensorsData.push({ 
                    y: sensorValue.y / 1000,
                    options: {
                        ...sensorValue.telegramPeriod, 
                    } 
                });
            }
        } else {
            const reportValue = reportsMapper?.value[index];
            if (reportValue && reportValue.y !== null) {
                reportsData.push({ 
                    y: reportValue.y,
                    options: {
                        ...reportValue.telegramPeriod, 
                    } 
                });
            }
            if (point && point.y !== null) {
                sensorsData.push({ 
                    y: point.y / 1000,
                    options: {
                        ...point.telegramPeriod, 
                    } 
                });
            }
        }
    });
    // check if we have no data or few data to hide the scrollbar and the reduce the scroll size
    // Calculate the height dynamically
    const barsCount = Math.max(reportsData.length, sensorsData.length); // Total number of bars    
    const barWidth = 20; // Set a fixed width for each bar (adjust as needed)
    const baseHeight = 120; // Additional height for margins, legends, titles, etc.
    const calculatedHeight = (baseHeight + barWidth) * barsCount; // Minimum height of 900px 
    data.chart.height = calculatedHeight;
    // for less than 4 serieries we decrease the width and remove the scrolling 
    if ((!reportsData.length  && !sensorsData.length) || (reportsData.length <= 3  || sensorsData.length <= 3)) data.chart.height = '644px';

    // data.yAxis.labels.overflow = 'auto';
    
    data.xAxis.categories = categories;
    data.xAxis.title.text = 'Fuel Consumption';
    data.series =[
            {
                name: 'Reports',
                data: reportsData,
                tooltipTitle: 'Reports',
                titleSuffix: '(UTC)',
                color: ThemeColorsForCharts()['reports_color'],
                legendIndex: 1,
                showInLegend: reportsStore().isTelegramConfigured
            },
            {
                name: 'Sensors',
                data: sensorsData,
                tooltipTitle: 'Sensors',
                titleSuffix: '(UTC)',
                color: ThemeColorsForCharts()['main_blue'],
                legendIndex: 0,
                showInLegend: !isLight
            },
        ];

    updateState(id, data, widget?.id);
}

export {legFuelConsumptionPayload, updateLegFuelConsumption};
