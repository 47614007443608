import Highcharts from "highcharts";
import { darkChartVariables, whiteChartVariables } from "Utilities/themeColorsForCharts";
import { userStore } from "common/store/storeUtils";
import moment from "moment";
import GlobalUtilities from "Utilities/global";
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);
require("highcharts/highcharts-more")(Highcharts);
require("highcharts/modules/no-data-to-display")(Highcharts);

const horizontalStackBarChartOptions = (unit) => {
	userStore().user?.theme !== "ds-white"
		? Highcharts.setOptions(Highcharts.darkTheme)
		: Highcharts.setOptions(Highcharts.whiteTheme);
    
    const returnThemeColor = (color) => {
        return userStore().user?.theme !== "ds-white" ? darkChartVariables[color] : whiteChartVariables[color]
    }

	let options = {
        chart: {
            type: "bar",
            marginTop: 75,
            marginBottom: 55,
            marginLeft: 70,
            height: '900px',
        },
        title: { text: ''},
        exporting: {
            enabled: false,
        },
        xAxis: {
            categories: [],
            title: {
                text: '',
                align: 'high',
                margin: 0,
                offset: 0,
                y: -10,
                x: 95,
                rotation: 0,
                style: {color: returnThemeColor('secondary_text'), fontSize: '11px', margin: 0}
            },
            offset: 0,
            labels: {
                align: 'center', 
                y:5
            },
            gridLineColor: returnThemeColor('lines_on_charts'),
            gridLineWidth: 1, // Show the grid line
            lineWidth: 0,      // Show the x-axis line
        },
        yAxis: {
            min: 0,
            title: {
                text: '',
                align: 'high', 
                textAlign: 'left',
                rotation: 0, 
                reserveSpace: false,
                x: 0, 
                y: 0,
                style: {
                    fontSize: '11px',
                    color: returnThemeColor('secondary_text'),
                    whiteSpace: 'nowrap'
                }
            },
            labels: {style:{"color": returnThemeColor('primary_text')}, y: 15, x: 0},
            showLastLabel: true,
            gridLineWidth: 0, // Dont show the grid line
            lineWidth: 0.5,      // Show the x-axis line
            lineColor: returnThemeColor('lines_on_charts'),

        },
        tooltip: {
            useHTML: true,
            shared: false,
            crosshairs: false,
            followPointer: false,
            xDateFormat: '%d/%m %H:%M',
            formatter: function () {

                const dateFrom = moment(this.point.options.dateFrom).utc().format("DD/MM/YYYY HH:mm");
                const dateTo = moment(this.point.options.dateTo).utc().format("DD/MM/YYYY HH:mm");

                return `<div class="flex-centered-col" style="min-width:150px;">
                                        <div class="highcharts-fonts tooltip-title"><b>
                                            ${dateFrom} - ${dateTo} (UTC)&nbsp;
                                        </b></div>
                                        <div class="flex-centered-col tooltip-values" style="width: 100%;">
                                        
                <div class="flex-space-evenly highcharts-fonts" id="${this.point.series.name}" style="width: 100%;">
                                    <div class="flex-space-between">
                                        <div class="tooltip-circle" style="background-color:${this.point.series.color};"></div>
                                        <div style="margin-right: 24px;">${this.point.series.userOptions.tooltipTitle}: &nbsp;</div>
                                    </div>
                                    <div><b>${GlobalUtilities.renderNumber(GlobalUtilities.roundToDigits(this.point.y, 2))} ${unit}</b></div>
                                </div>
                </div></div>`;
            }
        },        
        legend:{
            layout: 'horizontal',
            verticalAlign: 'top',
            itemMarginBottom: 25,
            alignColumns: false,
            itemStyle: {
                color: returnThemeColor('primary_text'),
                fontSize: '13px',
                fontFamily: "sans-serif",
                fontWeight: 600
            }
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    style: {
                        color: returnThemeColor('primary_text'),
                        fontSize: '11px',
                        fontFamily: 'sans-serif',
                        stroke: 'none'
                    },
                    formatter: function () {
                        return Highcharts.numberFormat(this.y, 2);
                    }
                }
            },
            series: {
                borderWidth: 0,
                borderRadius: 0,
                pointWidth: 20,
                groupPadding: 0.2,
                centerInCategory: true
            },
        },
        credits: {
            enabled: false
        },
        series: []
    }

	return options;
};

export default horizontalStackBarChartOptions;
