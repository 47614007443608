import React, {useState, useEffect} from 'react';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import stackBarChartOptions from 'components/charts/barChart/stackBarChart';
require("highcharts/modules/exporting")(Highcharts);
require("highcharts/modules/export-data")(Highcharts);


const BarChart = (props) => {

    const [options, setOptions] = useState(stackBarChartOptions());

    useEffect(() => {
        let options = {...stackBarChartOptions()};
        options.series = props.chartData.series;
        options.yAxis = props.chartData.yAxis;
        /* eslint react-hooks/exhaustive-deps: "off" */
        options.chart.height = props.style?.height || options.chart.height;
        options.exporting = {...props.chartData?.exporting, filename: props?.exportingChartTitle};
        if(props.noStack) {
            options.plotOptions = props.chartData.plotOptions;
            options.tooltip = props.chartData.tooltip;
        } else if (props.chartData.customTooltip) {
            options.tooltip = props.chartData.tooltip;
        }
        if(props.chartData.legend) {
            options.legend = props.chartData.legend;
        }
        setOptions(options);
    }, [props.chartData,props.noStack]);

    return (
        <HighchartsReact options={options} highcharts={Highcharts} />
    );
}
 
export default BarChart;