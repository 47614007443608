import React, { useEffect, useState } from 'react';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import {MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DatePickerLoader from './DatePickerLoader';
import {ReactComponent as DatePickerIcon} from 'assets/img/app/global-imgs/calendar.svg';
import classnames from 'classnames';
import {reportsStore} from 'common/store/storeUtils';

const Datepicker = (props) => {
    const [endDate, setEndDate] = useState(moment(props.to));
    const [increaseHeight, setIncreaseHeight] = useState(false);
    const [yearChangedCheck, setYearChangedCheck] = useState(true);

    useEffect(() => {
        if(!props.from || !props.to) return;
        setEndDate(moment(props.to));
        // eslint-disable-next-line
    }, [props.to]);

    const changeDatesRange = (endDate) => {        
        setEndDate(props.onlyMonths ? moment(endDate).utc().endOf('month') : moment(endDate).utc());
        props.changeDatesRange( props.onlyMonths ? moment(endDate).utc().startOf('month') : moment(endDate).utc(), props.onlyMonths ? moment(endDate).utc().endOf('month') : moment(endDate).utc() );
        if(props.onlyMonths) props.setCurrentMonth(moment(endDate).utc());
    }

    const checkIfIncreasePopoverHeight = (date) => {
        const daysInMonth = moment(date).daysInMonth();
        const firstDay = moment(date).startOf('month').format('dddd');
        if((daysInMonth === 31 && firstDay === 'Friday') || (daysInMonth >= 30 && firstDay === 'Saturday')) setIncreaseHeight(true);
        else setIncreaseHeight(false);
    }

    const monthChanged = async (date) => {
        checkIfIncreasePopoverHeight(date); // check if popover height needs to be increased
        if(!props.firstTelegramDate) return;
        return new Promise(resolve => resolveMonthChange(resolve, date));
    }

    const resolveMonthChange = async (resolve, date) => {
        await props.getMonthlyReportsDates(date);
        resolve();
    }

    const yearChanged = async (date) => {
        setYearChangedCheck(false);
        checkIfIncreasePopoverHeight(date); 
        if(!reportsStore().isTelegramConfigured) return setYearChangedCheck(true);
        const lastActiveDay = await props.getMonthlyReportsDates(date);
         //we use this flag to track when the year change to disable the new dates that are shown to the date picker
         //and also for a bug when we change year from the picker the year in the UI does not updated, this is caused because of the library 
         //we set it to true before getDailyReportsDates request because the request has an early return
        setYearChangedCheck(true);
        
        if(lastActiveDay && props.getDailyReportsDates) {
            await props.getDailyReportsDates(lastActiveDay);
        }
    }

    const enableDatePicker = () => {
        document.querySelector('.report-date-picker input.MuiInput-input').click();
    }

    const disableDays = (dayToCheck) => {
        if(!yearChangedCheck) return;
        if(!props.firstTelegramDate) return false;
        if(props.firstTelegramDate && moment(dayToCheck).valueOf() <= props.firstTelegramDate) return false;
        if(props?.activeDatesInMonth?.length)
            return !props?.activeDatesInMonth.some(day => {return moment(dayToCheck).isSame(moment(day), 'day')});
    }

    const getPopoverClasses = () => {
        return classnames('reportsDatePickerPopover', {'ReportsPopoverincreaseHeight': increaseHeight});
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePickerIcon className="calendar-icon" onClick={() => enableDatePicker()} />
            {
                props.onlyMonths ? (
                    <DatePicker
                        autoOk
                        variant="inline"
                        openTo="month"
                        views={["month"]}
                        minDate={new Date("2014-01-01")}
                        maxDate={moment().utc().endOf('month').subtract(1, 'day').toDate()}
                        value={moment(endDate)}
                        format="MMMM YYYY"
                        animateYearScrolling={true}
                        className="ds-input as-btn legReportDatePicker"
                        onChange={changeDatesRange}
                        PopoverProps = {{
                            className: 'reportsDatePickerPopover-leg',
                            style: {zIndex: 130000}
                        }}
                    />
                ) : (
                    <DatePicker
                        autoOk
                        variant="inline"
                        openTo="date"
                        minDate={new Date("2014-01-01")}
                        maxDate={moment().subtract(1,'day').set({hour:23,minute:59,second:59,millisecond:99}).toDate()}
                        value={moment(endDate)}
                        format="DD/MM/YYYY"
                        animateYearScrolling={true}
                        className="ds-input as-btn"
                        onChange={changeDatesRange}
                        onMonthChange = {monthChanged}
                        onYearChange = {yearChanged}
                        loadingIndicator = { <DatePickerLoader /> }
                        shouldDisableDate={disableDays}
                        disableFuture = {true}
                        maxDateMessage = {null}
                        PopoverProps = {{
                            className: getPopoverClasses(),
                            style: {zIndex: 130000}
                        }}
                    />
                )
            }
        </MuiPickersUtilsProvider>
    );
}

export default Datepicker;
