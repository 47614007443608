import React, { useEffect, useState } from 'react';
import NewDashboardCard from 'components/dashboard/newDashboardCard.jsx';
import SavedDashboardCard from 'components/dashboard/savedDashboardCard.jsx';
import TemplateModal from 'components/dashboard/modals/templateModal.jsx';
import UndoDeletePopup from 'components/dashboard/undoDeletePopup';
import Toaster from 'components/toaster/toaster';
import service from 'common/js/service';
import { useSelector, useDispatch } from 'react-redux';
import { dashboardStore } from 'common/store/storeUtils';
import GlobalUtilities from 'Utilities/global';

let activeTemplateIndex = -1, undoDelete = false, templatesBeforeDeleting = [];

const Dashboards = (props) => {
    const [templates, setTemplates] = useState([]);
    const [showCreateModal, setShowCreateModal] = useState(false);

    // store vars
    const dashboardStoreJSX = useSelector(state => state.dashboard);
    const dispatch = useDispatch();

    async function getDashboardTemplates() {
        const res = await service.getDashboardTemplates();
        if(res) setTemplates(res);
        if(props?.history?.location?.state?.onGoingDeletion) {
            const index = res.findIndex(template => template.id === dashboardStore().currentSelectedId);
            if(index > -1) dispatch({ type: 'dashboard/setShowUndoDeletePopup', payload: true });
        }
    }

    // for create functionality
    const openCreateModal = () => {setShowCreateModal(true);}
    const closeCreateModal = () => {setShowCreateModal(false);}

    async function createAction(title) {
        const res = await service.createDashboardTemplate(title);
        if(res.status === 201) {
            const locationUrl = res.headers.get('Location');
            const resp = await service.getDashboardCreatedTemplate(locationUrl);
            setTemplates([...templates, resp]);

            dispatch({ type: 'dashboard/setEditMode', payload: true });

            props.history.push(`/dashboard/${resp.id}`);
        }
    }

    // for rename functionality
    const renameAction = async (newTitle, id) => {
        const res = await service.renameDashboardTemplate(newTitle, id);
        if(res.status === 204) {
            const tempTemplates = templates.map(template => template.id === id ? {...template, title: newTitle} : template);
            setTemplates(tempTemplates);
        }
    };

    const handleTemplateRename = (id, title) => {
        dispatch({ type: 'dashboard/setShowRenameModal', payload: true });
        dispatch({ type: 'dashboard/setCurrentSelectedId', payload: id });
        dispatch({ type: 'dashboard/setCurrentSelectedTitle', payload: title });
    }

    // for duplicate functionality
    const duplicateAction = async (sameTitle, id) => {
        const res = await service.duplicateDashboardTemplate(sameTitle, id);
        if(res.status === 201) {
            const locationUrl = res.headers.get('Location');
            const duplicatedTemplate = await service.getDashboardDuplicatedTemplate(locationUrl);
            const tempTemplates = [...templates];
            const index = templates.findIndex(template => template.id === id);
            activeTemplateIndex = index;
            tempTemplates.splice(index, 0, duplicatedTemplate);
            setTemplates(tempTemplates);
        }
    };

    const handleTemplateDuplicate = (title, id) => {
        duplicateAction(title, id);
    }

    // for delete functionality
    const deleteAction = async (id) => {
        const res = await service.deleteDashboardTemplate(id);
        if(res.status === 204) {
            const tempTemplates = [...templates];
            const index = templates.findIndex(template => template.id === id);
            tempTemplates.splice(index, 1);
            setTemplates(tempTemplates);    
        }
    }
    
    const handleTemplateDelete = (id) => {
        dispatch({ type: 'dashboard/setCurrentSelectedId', payload: id });
        dispatch({ type: 'dashboard/setShowUndoDeletePopup', payload: true });
    }

    useEffect(() => {
        const controller = new AbortController();
        GlobalUtilities.signal = controller.signal;
        
        activeTemplateIndex = -1;
        getDashboardTemplates(); 

        return () => {
            controller.abort();
            GlobalUtilities.signal = null;
        }
    // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(dashboardStore().showUndoDeletePopup) { // remove temporarily template from ui list, and if timeout finishes, delete it permanently
            activeTemplateIndex = -1;
            const tempTemplates = [...templates];
            templatesBeforeDeleting = [...templates];
            const index = templates.findIndex(template => template.id === dashboardStore().currentSelectedId);
            tempTemplates.splice(index, 1);
            setTemplates(tempTemplates);
            setTimeout(() => {
                if(!undoDelete) {
                    deleteAction(dashboardStore().currentSelectedId);
                    dispatch({ type: 'dashboard/setShowUndoDeletePopup', payload: false });
                }
                undoDelete = false;
            }, [3000]);
        }
    // eslint-disable-next-line
    }, [dashboardStoreJSX.showUndoDeletePopup])

    const popupUndoHandler = () => { // if user clicks the undo button, place the removed template back to the ui list
        undoDelete = true;
        const index = templatesBeforeDeleting.findIndex(template => template.id === dashboardStore().currentSelectedId);
        putDeletedTemplateBack(index);
        dispatch({ type: 'dashboard/setShowUndoDeletePopup', payload: false });
    }

    const putDeletedTemplateBack = (index) => {
        activeTemplateIndex = index;
        setTemplates(templatesBeforeDeleting);
    }

    return(
        <div className = "dashboardTemplates">
            <div className="title">
                DASHBOARD TEMPLATES
            </div>
            <div className="templates">
                <NewDashboardCard openModal={openCreateModal} />
                {
                    templates.map((template, index) => {
                        return <SavedDashboardCard 
                                    {...props} 
                                    key={template.id} 
                                    {...template} 
                                    duplicateAction={duplicateAction} 
                                    active={index===activeTemplateIndex} 
                                    handleTemplateDelete={handleTemplateDelete} 
                                    handleTemplateDuplicate={handleTemplateDuplicate} 
                                    handleTemplateRename={handleTemplateRename} 
                                />;
                    })
                }
            </div>
            <TemplateModal 
                header="CREATE DASHBOARD" 
                submitButtonLabel="Create" 
                show={showCreateModal} 
                dashboardTemplateAction={createAction} 
                closeModal={closeCreateModal} 
            />
            <TemplateModal 
                header="RENAME DASHBOARD" 
                submitButtonLabel="Save" 
                show={dashboardStoreJSX.showRenameModal} 
                dashboardTemplateAction={renameAction} 
                closeModal={() => dispatch({ type: 'dashboard/setShowRenameModal', payload: false })} 
                currentSelectedTitle={dashboardStoreJSX.currentSelectedTitle} 
            />
            {
                dashboardStoreJSX.showUndoDeletePopup 
                && <Toaster 
                        toasterClasses='bottom-center' 
                        toasterComponent={UndoDeletePopup} 
                        action={popupUndoHandler}
                    />
            }
        </div>
    )
}

export default Dashboards;
