import React, { useEffect, useState } from 'react';
import ChipComponent from 'components/chips/chipComponent';
import moment from 'moment';
import classnames from 'classnames';
import { ReactComponent as Arrow } from 'assets/img/app/global-imgs/small-arrow.svg';
import { ReactComponent as Ballast } from 'assets/img/app/global-imgs/ballast-small.svg';
import { ReactComponent as Laden } from 'assets/img/app/global-imgs/laden-small.svg';

let _legs = null;

const LegsToolbar = (props) => {
    const toolbarEl = React.createRef();
    const arrowEl = React.createRef();
    const [showExtendArea, setShowExtendArea] = useState(false);

    const handleExpand = () => {
        arrowEl.current.classList.toggle('rotateArrow');
        if( parseInt(toolbarEl.current.style.height) === parseInt(toolbarEl.current.scrollHeight) ) toolbarEl.current.style.height = '47px';
        else toolbarEl.current.style.height = `${toolbarEl.current.scrollHeight+18}px`;
    }

    const handleClickOutside = event => {
        if( !document.getElementById('legs-toolbar').contains(event.target)
            && document.getElementById('legs-toolbar').style.height !== '47px'
            && document.getElementById("legs-toolbar-arrow") ) {
                document.getElementById('legs-toolbar').style.height = '47px';
                document.getElementById("legs-toolbar-arrow").classList.toggle('rotateArrow');
        }
    }

    useEffect(() => {
        if( !toolbarEl.current || (props.legs === _legs) ) return;

        _legs = props.legs;
        const isOverflowing = (toolbarEl.current.clientHeight < toolbarEl.current.scrollHeight);

        if( isOverflowing ) {
            setShowExtendArea(true);
            toolbarEl.current.classList.add('morePadding');
        } else {
            setShowExtendArea(false);
            toolbarEl.current.classList.remove('morePadding');
        }
    }, [toolbarEl, props.legs]);

    useEffect(() => {
        document.getElementById('legs-toolbar').style.height = '47px';
        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
        // eslint-disable-next-line
    }, []);

    const getChipExtraClasses = index => classnames({'active': index === props.activeLegIndex});

    return (
        <div id="legs-toolbar" className="legs-toolbar d-flex justify-content-start flex-shrink-1 full-width" ref={toolbarEl}>
            <div className="d-flex justify-content-start">
                <div className="d-flex justify-content-start flex-wrap align-items-center flex-shrink-1">
                    {props.legs.map((leg, index) => (
                        <ChipComponent
                            key={leg.dateFrom + index}
                            icon={leg.ballastFlag !== null  ? ((leg.ballastFlag === 'B' || leg.ballastFlag === 'HB') ? <Ballast className='svg-path' /> : <Laden className='svg-path' />) : null}
                            text={`${moment(leg.dateFrom).utc().format('DD/MM')} - ${moment(leg.dateTo).utc().format('DD/MM')} \xa0 ${leg.portFrom} - ${leg.portTo}`}
                            extraProps={{onClick: () => props.updateParentState('activeLegIndex', index)}}
                            extraClasses={getChipExtraClasses(index)}
                        />
                    ))}
                </div>
                {showExtendArea && <div className="legs-toolbar__extend-area" onClick={handleExpand}>
                    <div className="legs-toolbar__extend-area__content d-flex justify-content-center align-items-center flex-shrink-0">
                        <div className="legs-toolbar__extend-area__content__devider"></div>
                        <Arrow id="legs-toolbar-arrow" className="legs-toolbar__extend-area__content__arrow svg-path" ref={arrowEl} />
                    </div>
                </div>}
            </div>
        </div>
    );
}
 
export default LegsToolbar;