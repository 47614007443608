import React from 'react';
import SensorsTelegramsDataDivided from 'reports/SensorsTelegramsDataDivided';
import {ReactComponent as FuelDropsIcon} from 'assets/img/app/global-imgs/fuel-drops.svg';
import { ReactComponent as MeIcon } from 'assets/img/app/monitoring-imgs/meEventIcon.svg';
import {ReactComponent as GeIcon} from 'assets/img/app/monitoring-imgs/generator-engine-icon.svg';
import { ReactComponent as BoilersIcon } from 'assets/img/app/boilers-imgs/boilerEventIcon.svg';
import keys from 'lodash/keys';
import { licensing } from 'common/store/licensing';
import { vesselUtils } from 'common/store/storeUtils';

const getFuelMetricsObj = (props) => {
    return {
        me: {
            title: 'Main Engine',
            iconComponent: <MeIcon className="svg-path" />,
            sensorsValue: props?.data?.data?.totalFuelMESensors,
            reportsValue: props?.data?.data?.totalFuelMEReports,
            missingData: props?.data?.data?.totalFuelMESensorsMissing
        },
        ge: {
            title: 'Generator Engine',
            iconComponent: <GeIcon className="svg-path" />,
            sensorsValue: props?.data?.data?.totalFuelGESensors,
            reportsValue: props?.data?.data?.totalFuelGEReports,
            missingData: props?.data?.data?.totalFuelGESensorsMissing
        },
        bo: {
            title: 'Boilers',
            iconComponent: <BoilersIcon className="svg-path" />,
            sensorsValue: props?.data?.data?.totalFuelBOSensors,
            reportsValue: props?.data?.data?.totalFuelBOReports,
            missingData: props?.data?.data?.totalFuelBOSensorsMissing
        }
    }
}

const TotalFuelConsumption = (props) => {
    const isLight = props?.widget ? licensing.lightCondition(null, vesselUtils.getObjOfAVessel(props?.widget?.vesselIds[0])) : licensing.lightCondition();
    
    return(
        <div className='totalFuelConsumptionWidget flex-space-between-col'>
            <div className='full-height flex-centered-col flex-wrap'>
                <div className='d-flex totalFuelConsumptionWidget__body__wrapper'>
                    <FuelDropsIcon className="svg-path" />
                    <div className='totalFuelConsumptionWidget__body__text headline-metric'>
                        <SensorsTelegramsDataDivided
                            // sensorsValue represents SENSOR or AIS data based on the license but for vessels with light license we only show reports
                            sensorsValue={!isLight && props?.data?.data?.totalFuelSensors}
                            reportsValue={props?.data?.data?.totalFuelReports} 
                            classes={{dividerClasses: 'textDivider big', sensorsClass: isLight ? 'ais' : 'sensor', reportsClass: 'report', aisClass: 'ais', wrapperClass: 'flex-centered'}}
                            unit='mt' 
                        />
                        <div className='label'>TOTAL FUEL CONSUMPTION</div>
                        {
                            props?.data?.data?.totalFuelSensorsMissing && 
                            <div className="missingFuelData small-label">
                                ({props?.data?.data?.totalFuelSensorsMissing}% Missing Data)
                            </div>
                        }
                    </div>
                </div>
                {props?.isLegReportWidget && <div className='textHorizontalDivider huge' />}
                {
                    props?.isLegReportWidget && (
                        <div className='fuel-consumption-metrics'>
                            {
                                keys(getFuelMetricsObj(props)).map((metricCategory, index) => {
                                    return(
                                        <div className='d-flex align-items-start metricsData' key={index}>
                                            {getFuelMetricsObj(props)[metricCategory]?.iconComponent}
                                            <div className='totalFuelConsumptionWidget__body__text info-value'>
                                                <SensorsTelegramsDataDivided 
                                                    sensorsValue={getFuelMetricsObj(props)[metricCategory]?.sensorsValue} 
                                                    reportsValue={getFuelMetricsObj(props)[metricCategory]?.reportsValue} 
                                                    classes={{dividerClasses: 'textDivider big', sensorsClass: isLight ? 'ais' : 'sensor', reportsClass: 'report', wrapperClass: 'totalFuelConsumptionWidget__body__text__values'}}
                                                    unit='mt' 
                                                />
                                                <div className='label'>{getFuelMetricsObj(props)[metricCategory]?.title}</div>
                                                {
                                                    getFuelMetricsObj(props)[metricCategory]?.missingData && 
                                                    <div className="missingFuelData small-label">
                                                        ({getFuelMetricsObj(props)[metricCategory]?.missingData}% Missing Data)
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default TotalFuelConsumption;