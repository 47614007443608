import React from 'react';
import keys from 'lodash/keys';

// gets the object with all the fuel data and keeps only the ones with values
const getExistingFuels = (fuelTypesObj) => {
    const existingOnlyFuels = {};
    for (const key in fuelTypesObj) {
        if (fuelTypesObj[key]) {
            existingOnlyFuels[key] = fuelTypesObj[key];
        }
    }
    return existingOnlyFuels;
};

// gets the object with all the fuel data and returns true if at least one fuel exists
export const isFuelsAvailable = (fuelTypesObj) => {
    return keys(getExistingFuels(fuelTypesObj)).length;
};

const TotalFuelConsumptionFooter = (props) => {
    const existingFuelsOnly = getExistingFuels(props?.data?.data?.fuelConsumptionTypesData);

    return (
        <div className="totalFuelConsumptionFooterWidget">
            <div className='totalFuelConsumptionFooterWidget__footer small-label'>
                    <div className='totalFuelConsumptionFooterWidget__footer__body flex-centered-start flex-wrap'>
                        <div>Type of Fuel Consumption: &nbsp;</div>
                        {
                            keys(existingFuelsOnly).map((type, index) => {
                                return existingFuelsOnly[type] && <React.Fragment key={index}>
                                    {index !== 0 && <div className='textDivider small'></div>}
                                    <div>{type}: &nbsp;</div>
                                    <div className='report'>{existingFuelsOnly[type]} mt</div>
                                </React.Fragment>
                            })
                        }
                    </div>
            </div>
        </div>

    );
}

export default TotalFuelConsumptionFooter;
