import moment from 'moment';
import registerWidgetsStore from "common/store/registerWidgetsStore";
import { vesselStore, vesselUtils } from 'common/store/storeUtils';

const validLicenses = ['cp', 'cl'];

const licensingUtils = {
    lisenseMapper: lisenseName => {
        const mapper = {
            'cl': true,
            'cp': false
        }
        return mapper[lisenseName];
    },

    lisenseBelongsToCassandra: lisenseName => {
        return validLicenses.includes(lisenseName);
    },

    checkLisense: lisense => {
        // the registerWidgetsStore will contain the correct fromTo value, depending on what page we are (monitoring pages, reports pages)
        // this happens because it is always updated with the monitoring or reports stores values, when user navigates to those pages
        const appFrom = registerWidgetsStore.fromTo?.from ? registerWidgetsStore.fromTo.from : moment();
        const appTo = registerWidgetsStore.fromTo?.to ? registerWidgetsStore.fromTo.to : moment();

        // if we don't have 'activeTo', this means that this is the current license
        // and if period from (reports or monitoring period) is greater that the from of the license, then return this license
        if (!lisense.activeTo && appFrom > lisense.activeFrom) return lisense.name;
        if (appFrom > moment(lisense.activeFrom).valueOf() || appTo < moment(lisense.activeTo).valueOf()) return lisense.name;
    },

    checkLisensesObject: lisensesObj => {
        let domLisense = null;

        if(!lisensesObj) return false;
        
        // keep only cassandra licenses
        lisensesObj = lisensesObj.filter(lisense => licensingUtils.lisenseBelongsToCassandra(lisense?.name))

        // sort by recent activeFrom date
        lisensesObj = lisensesObj.sort((a, b) => b.activeFrom.localeCompare(a.activeFrom));

        // recursively check licenses object for a dominating lisense
        for (let i = 0; i < lisensesObj.length; i++) {
            domLisense = licensingUtils.checkLisense(lisensesObj[i]);
            if (domLisense) break;
        }

        return (licensingUtils.lisenseMapper(domLisense));
    }
}

export const licensing = {
    lightCondition: (licenses, vessel) => {
        if (vessel) return licensingUtils.checkLisensesObject(vessel.licenses);
        if (!licenses) return licensingUtils.checkLisensesObject(vesselUtils.getSelectedVesselData().licenses);
        return licensingUtils.checkLisensesObject(licenses);
    },
    
    fleetLightCondition: () => {
        if (vesselStore().fleetVessels.filter(vessel => !vessel.isPremium).length > 0) return true;
        return false;
    },

    fleetAllVesselsAreLight: () => {
        return vesselStore().fleetVessels.every(vessel => !vessel.isPremium);
    },

    getCurrentLicense: licenses => licenses.find(license => license.status),

}