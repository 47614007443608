import {licensing} from "common/store/licensing";
import Wind from 'Utilities/wind';
import Utilities from "Utilities/global";
import overviewStorage from 'components/monitoringCategories/localStorages/overviewStorage';
import { vesselUtils } from "common/store/storeUtils";
import moment from 'moment';
import { downloadChartInCSVform, downloadChartInXLSXform } from "Utilities/highchartsUtils";
import { ThemeColorsForCharts } from "Utilities/themeColorsForCharts";

const vesselMonitoringChartPayload = {
    xAxis: "TIME",
    aggregation: "AVG",
    withNavigationStatus: true,
    withTelegramNavigationStatus: true,
    metrics: [{
        metricCategory: "MAIN_ENGINE",
        subIds: [1],
        metricData: [
            {metricName: "foVolConsumptionAbsolute"},
            {metricName: "rpm"}
        ]
    }, {
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "telegraphAgentUse"},
            {metricName: "speed_overground"},
            {metricName: "apparent_wind_speed_calc"},
            {metricName: "apparent_wind_speed" },
            {metricName: "true_wind_speed_calc" },
            {metricName: "apparent_wind_direction_calc"},
            {metricName: "apparent_wind_angle"},
            {metricName: "true_current_speed_calc"},
            {metricName: "true_current_direction_calc"},
        ]
    }]
}

const vesselMonitoringChartPayloadLight = {
    xAxis: "TIME",
    aggregation: "AVG",
    withTelegramNavigationStatus: true,
    withNavigationStatus: true,
    metrics: [{
        metricCategory: "VESSEL",
        subIds: [1],
        metricData: [
            {metricName: "telegraphAgentUse"},
            {metricName: "ais_speed_overground"},
            {metricName: "apparent_wind_speed_calc"},
            {metricName: "true_wind_speed_calc" },
            {metricName: "apparent_wind_direction_calc"},
            {metricName: "true_current_speed_calc"},
            {metricName: "true_current_direction_calc"},
        ]
    }, {
        metricCategory: "TELEGRAM",
        subIds: [1],
        metricData: [{
            metricName: "me_1_foConsumption_calc"
        }, {
            metricName: "me_1_rpm_avg"
        }
        ]
    }]
}

const navigationStatusSeries = {
    name: '',
    data: {},
    lineWidth: 4,
    pointPlacement: 5000000000,
    type: 'line',
    enableMouseTracking: false,
    yAxis: 'navigationStatus-axis',
    color: '#CAA3FF',
    states: {
        inactive: {
            opacity: 1
        }
    },
}
const telegraphAgentStatusSeries = {
    name: '',
    data: {},
    lineWidth: 4,
    pointPlacement: 5000000000,
    type: 'line',
    enableMouseTracking: true,
    yAxis: 'telegraphAgentStatus-axis',
    color: '#CAA3FF',
    states: {
        inactive: {
            opacity: 1
        }
    },
	tooltip: {
		pointFormatter: function () {    
            if(this.status === null || this.isTooltipDisabled) return '';
			return `
                <div class="flex-centered-col" style="min-width:150px;">
                    <div class="flex-centered-col tooltip-values" style="width: 100%;">           
                        <div class="flex-space-evenly highcharts-fonts" id="telegraph-agent-tooltip" style="width: 100%;">
                            <div class="flex-space-between">
                                <div class="tooltip-circle" style="background-color:${this.color};"></div>
                                <div style="margin-right: 5px;">TA: &nbsp;</div>
                            </div>
                            <div><b>${this.status === true ? 'On' : this.status === false ? 'Off' : '-'} </b></div>
                        </div>
                    </div>
                </div>
            `;
		}
	}
}

const supportedNavigationStatuses = ["DEPARTURE", "ARRIVAL"];

const vesselMonitoringChartUpdate = (id, response, updateState, extraChartConfigs, widget) => {
        
    const series = [{data: []}, {data: []}, {data: []}, {data: []}, {data: []}, {data: []}], plotLinesArray = [],
        navigationStatusLine = [],
        telegraphAgentStatusLine = [];
    const helpingMetrics = [{data: []}, {data: []}];
    let data = {};

    let navigationStatusLabels = [];
    let telegraphAgentStatusLabels = [];
    let supportedStatusPlotLines = [];

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    overviewStorage.setVesselMonitoringChartData(response);

    if (response?.length && response[0] && response[0].values) {
        Wind.findWindSpeedWithPriority(response, ['apparent_wind_speed_calc', 'apparent_wind_speed', 'true_wind_speed_calc'], series, 2);

        response[0].values.forEach(keyGp => {
            if (keyGp.metricName === 'true_current_speed_calc') {
                series[0].data = keyGp.value;
            }
            if (!isLight) {
                if (keyGp.metricName === 'speed_overground') series[1].data = keyGp.value;
            } else {
                if (keyGp.metricName === 'ais_speed_overground') series[1].data = keyGp.value;
            }
            if (!isLight) {
                if (keyGp.metricName === 'rpm') series[4].data = keyGp.value;
            } else {
                if (keyGp.metricName === 'me_1_rpm_avg') {
                    keyGp.value.forEach(value => {
                        value.y = parseFloat(value?.y)
                    })
                    series[4].data = keyGp.value;
                }
            }
            if (!isLight) {
                if (keyGp.metricName === 'foVolConsumptionAbsolute') series[5].data = keyGp.value; series[5].unit = ' kg/min';
            } else {
                if (keyGp.metricName === 'me_1_foConsumption_calc') {
                    keyGp.value.forEach(value => {
                        value.y = parseFloat(value?.y)
                    })
                    series[5].data = keyGp.value;
                    series[5].unit = ' mt';
                }
            }
            if (keyGp.metricName === 'apparent_wind_angle') helpingMetrics[0].data = keyGp.value;
            if (keyGp.metricName === 'apparent_wind_direction_calc' && (keyGp.value || keyGp.value === 0)) helpingMetrics[0].data = keyGp.value;
            if (keyGp.metricName === 'true_current_direction_calc') helpingMetrics[1].data = keyGp.value;
            if (keyGp.metricName === 'telegraphAgentUse') telegraphAgentStatusLabels = keyGp.value;

        });
    }

    if (series[0].data) series[0].data = series[0].data.map((arr, index) => {
        const direction = helpingMetrics[1]?.data[index]?.y;
        return {
            x: arr.x,
            y: arr.y,
            z: Wind.convertApparentWindAngle(direction) === '-' ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
        };
    });

    if (series[2].data) series[2].data = series[2].data.map((arr, index) => {
        const direction = helpingMetrics[0]?.data?.length > 0 && helpingMetrics[0]?.data[index]?.y;
        return {
            x: arr.x,
            y: Wind.knotsToBeauforts(arr.y) === '-' ? null : Wind.knotsToBeauforts(arr.y),
            z: Wind.convertApparentWindAngle(direction) === '-' ? '' : ` (${Wind.convertApparentWindAngle(direction)}) `
        };
    });

    
    if(response[0]?.navigationStatus) {
        navigationStatusLabels = response[0]?.navigationStatus[0]?.statuses?.filter(status => !supportedNavigationStatuses.includes(status.status));
        supportedStatusPlotLines = response[0]?.navigationStatus[0]?.statuses?.filter(status => supportedNavigationStatuses.includes(status.status));
    }
    

    navigationStatusLabels?.length > 0 && navigationStatusLabels.forEach((status, index) => {

        let serieWithData = [];
        response[0].values.forEach(obj => {if(obj.value.length) serieWithData = obj.value});

        /** Get navigation status datetime, and connect each one with the next one
         For the last status, connect it with the last datetime of the main response body */
        const median = moment((navigationStatusLabels[index].datetime +
            (navigationStatusLabels[index + 1] ? navigationStatusLabels[index + 1].datetime : serieWithData[serieWithData.length-1].x)) / 2).valueOf();

        navigationStatusLabels[index] && !supportedNavigationStatuses.includes(status.status) && navigationStatusLine.push({
            ...navigationStatusSeries,
            color: Utilities.getNavigationStatus(status.status).color,
            data: [{
                "x": navigationStatusLabels[index].datetime, "y": 1,
                status: Utilities.getNavigationStatus(status.status).name
            }, {
                "x": navigationStatusLabels[index + 1] ?
                    moment(navigationStatusLabels[index + 1].datetime).subtract(30, 'seconds').valueOf() :
                    serieWithData[serieWithData.length-1].x,
                "y": 1,
                status: Utilities.getNavigationStatus(status.status).name
            }]
        })
        
        plotLinesArray.push({
            value: median,
            width: 0,
            color: Utilities.getNavigationStatus(status.status).color,
            label: {
                text: `<div class="daily-reports-status-label plotlines-label" style="background-color:${Utilities.getNavigationStatus(status.status).color}">
                            ${Utilities.getNavigationStatus(status.status).name}
                        </div>`,
                rotation: 0,
                y: telegraphAgentStatusLabels.length > 0 ? -80 : -60, x: -3,
                useHTML: true,
                align: 'center',
                style: {
                    fontSize: '12px'
                }
            }
        })
    })
    
    telegraphAgentStatusLabels?.length > 0 && telegraphAgentStatusLabels.forEach((status, index) => {

        let serieWithData = [];
        response[0].values.forEach(obj => {if(obj.value.length) serieWithData = obj.value});

        /** Get navigation status datetime, and connect each one with the next one
         For the last status, connect it with the last datetime of the main response body */
        const median = moment((telegraphAgentStatusLabels[index].x +
            (telegraphAgentStatusLabels[index + 1] ? telegraphAgentStatusLabels[index + 1].x : serieWithData[serieWithData.length-1].x)) / 2).valueOf();
                
        telegraphAgentStatusLabels[index] && telegraphAgentStatusLine.push({
            ...telegraphAgentStatusSeries,
            color: status.y === true ?  ThemeColorsForCharts()['fine_text'] : status.y === false ? ThemeColorsForCharts()['disabled_surface']: 'transparent',
            name: '',
            data: [{
                "x": telegraphAgentStatusLabels[index].x,
                "y": 1,
                status: status.y,
            }, {
                "x": telegraphAgentStatusLabels[index + 1] ?
                    moment(telegraphAgentStatusLabels[index + 1].x).valueOf() :
                    serieWithData[serieWithData.length-1].x,
                "y": 1,
                status: status.y,
                isTooltipDisabled: true,
            }]
        })
        
        plotLinesArray.push({
            value: median,
            width: 0,
            color: status.y === true ?  ThemeColorsForCharts()['fine_text'] : ThemeColorsForCharts()['disabled_surface'],
        })
    })

    supportedStatusPlotLines?.length > 0 && supportedStatusPlotLines.forEach(status => {
        plotLinesArray.push({
            value: status.datetime,
            dashStyle: 'LongDash',
            width: 2,
            color: Utilities.getNavigationStatus(status.status).color,
            label: {
                style: {
                    display: 'none'
                }
            }
        })
    })

    // check if series has empty data and assign empty object if it has
    if (!(series.every((obj) => obj?.data?.length === 0))) {
        data = {
            data: {
                xAxis:  {
                    offset: telegraphAgentStatusLabels.length > 0 ? 0 : 10
                },
                chart: {id: id},
                marginTop: 35,
                height: 830,
                yAxis: [
                    {
                        id: 'navigationStatus-axis',
                        title: {
                            text: 'Navigation Status', 
                            style: {fontSize: "10px", color: ThemeColorsForCharts()['secondary_text']},
                            y: -16
                        },
                        min: 0,
                        height: '38px',
                        top: '50px',
                        offset: 32,
                        gridLineColor: 'transparent',
                        labels: {enabled: false},
                        opposite: false

                    },
                    telegraphAgentStatusLabels.length > 0 && {
                        id: 'telegraphAgentStatus-axis',
                        title: {
                            text: 'Telegraph Agent', 
                            style: {fontSize: "10px", color: ThemeColorsForCharts()['secondary_text']},
                            y: -4
                        },
                        min: 0,
                        height: '38px',
                        offset: 32,
                        top: '85px',
                        gridLineColor: 'transparent',
                        labels: {enabled: false},
                        opposite: false
                    },
                    {
                        id: 'fc-axis',
                        title: {text: `Fuel Consumption (${series[5]?.unit})`, style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: telegraphAgentStatusLabels.length > 0 ? '128px' : '108px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'rpm-axis',
                        title: {text: 'RPM', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: telegraphAgentStatusLabels.length > 0 ? '238px' : '228px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'sog-axis',
                        title: {text: 'Speed Over Ground (Kn)', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: telegraphAgentStatusLabels.length > 0 ? '358px' : '348px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'wind-axis',
                        title: {text: 'Wind (Bf)', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: telegraphAgentStatusLabels.length > 0 ? '478px' : '468px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    },
                    {
                        id: 'current-axis',
                        title: {text: 'Currents (Kn)', style: {fontSize: "10px"}},
                        min: 0,
                        height: '80px',
                        top: telegraphAgentStatusLabels.length > 0 ? '598px' : '588px',
                        allowDecimals: false,
                        lineWidth: 0,
                        offset: 2,
                        opposite: false
                    }
                ],
                series: [
                    {
                        name: 'Fuel Consumption',
                        data: series[5].data,
                        tooltipTitle: 'FC',
                        type: 'line',
                        titleSuffix: '',
                        yMin: 0,
                        yAxis: 'fc-axis',
                        tooltip: {valueSuffix: series[5].unit, valueDecimals: 1},
                    },
                    {
                        name: 'RPM',
                        data: series[4].data,
                        tooltipTitle: 'RPM',
                        type: 'line',
                        titleSuffix: '',
                        yMin: 0,
                        yAxis: 'rpm-axis',
                        tooltip: {valueSuffix: ``, valueDecimals: 1},
                    },
                    {
                        name: 'SOG',
                        data: series[1].data,
                        tooltipTitle: 'SOG',
                        type: 'line',
                        titleSuffix: '(UTC)',
                        yMin: 0,
                        yAxis: 'sog-axis',
                        tooltip: {valueSuffix: ` Kn`, valueDecimals: 1},
                    },
                    {
                        name: 'Wind',
                        data: series[2].data,
                        tooltipTitle: 'Wind',
                        type: 'line',
                        titleSuffix: '(UTC)',
                        yMin: 0,
                        yAxis: 'wind-axis',
                        tooltip: {valueSuffix: ` Bf`, valueDecimals: 0},
                    },
                    {
                        name: 'Currents',
                        data: series[0].data,
                        tooltipTitle: 'Currents',
                        type: 'line',
                        titleSuffix: '(UTC)',
                        yMin: 0,
                        yAxis: 'current-axis',
                        tooltip: {valueSuffix: ` Kn`, valueDecimals: 1},
                    },
                    ...navigationStatusLine,
                    ...telegraphAgentStatusLine,
                ],
                navigationStatus: response[0] && response[0].navigationStatus,
                telegraphAgentStatus: response[0] && response[0].navigationStatus,
                title: {
                    align: 'left',
                    y: 25,
                    x: 37,
                    style: {
                        color: '#687685',
                        fontSize: '11px'
                    }
                },
                plotLines: plotLinesArray,
                exporting: {
                    enabled: true,
                    buttons: {contextButton: {
                        menuItems: [
                            "download",
                            "customSeparator",
                            {
                                text: "XLSX",
                                onclick: function () {
                                    let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-VESSEL'S MONITORING`;
                                    
                                    let sheetsName = name
                                    const rows = this.getDataRows(true);
                                    let headers = this.getDataRows(true)[0].slice(0, 6);
                
                                    if (sheetsName.length > 30) {
                                        sheetsName = "VESSEL'S MONITORING"
                                    }

                                    const filteredRows = rows.reduce((acc, row) => {
                                        if (row.length > 0 && row.x) {
                                            // Use the 'x' timestamp for the date formatting instead of row[0]
                                            const formattedRow = [
                                                moment.utc(row.x).format('DD-MM-YYYY HH:mm:ss'),
                                                ...row.slice(1, 6)
                                            ];
                                            // Check if the row is not empty after formatting (ignoring falsy values)
                                            if (formattedRow.slice(1).some(item => item !== null && item !== '' && item !== undefined && !isNaN(item))) {
                                                acc.push(formattedRow);
                                            }
                                        }
                                        return acc;
                                    }, []);

                                    headers = headers.map((val) => {
                                        if (typeof val !== 'string') return val; // Some values are not string and we cant do string manipulations 
                                        return val.toUpperCase().includes("TIME") ? val
                                            : val.toUpperCase().includes('CONSUMPTION') ? `${val} ${isLight ? '(mt)' : '(kg/min)'}`
                                            : val.toUpperCase().includes('SOG') ? `${val} (Kn)`
                                            : val.toUpperCase().includes('WIND') ? `${val} (BF)`
                                            : val.toUpperCase().includes('CURRENTS') ? `${val} (Kn)`
                                            : val;
                                    });
                
                                    let updatedows = [headers.slice(0,6), ...filteredRows.slice(0, rows.length)];            
                
                                    // remove special characters before the download function because special characters are causing a bug on the download
                                    // eslint-disable-next-line no-useless-escape
                                    name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                                    name = name.replace(/\./g, "");
                
                                    // eslint-disable-next-line no-useless-escape
                                    sheetsName = sheetsName.replace(/[:\/\\\?\*\[\]]/g, "");
                                    downloadChartInXLSXform({
                                        xlsxRows: updatedows,
                                        sheetsName: sheetsName,
                                        name: name
                                    });
                                }
                            },
                            {
                                text: "CSV",
                                onclick: function () {
                                    let name = `${vesselUtils.getVesselName(widget.vesselIds[0])}-VESSEL'S MONITORING`;
                                    // eslint-disable-next-line no-useless-escape
                                    name = name.replace(/[:\/\\\?\*\[\]]/g, "");
                                    name = name.replace(/\./g, "");
                                    const rows = this.getDataRows(true);
                                    let headers = this.getDataRows(true)[0].slice(0, 6);

                                    const filteredRows = rows.reduce((acc, row) => {
                                        if (row.length > 0 && row.x) {
                                            // Use the 'x' timestamp for the date formatting instead of row[0]
                                            const formattedRow = [
                                                moment.utc(row.x).format('DD-MM-YYYY HH:mm:ss'),
                                                ...row.slice(1, 6)
                                            ];

                                            // Check if the row is not empty after formatting (ignoring falsy values)
                                            if (formattedRow.slice(1).some(item => item !== null && item !== '' && item !== undefined && !isNaN(item))) {
                                                acc.push(formattedRow);
                                            }
                                        }
                                        return acc;
                                    }, []);
                                    
                                    headers = headers.map((val) => {
                                        if (typeof val !== 'string') return val; // Some values are not string and we cant do string manipulations   
                                        return val.toUpperCase().includes("TIME") ? val
                                            : val.toUpperCase().includes('CONSUMPTION') ? `${val} ${isLight ? '(mt)' : '(kg/min)'}`
                                            : val.toUpperCase().includes('SOG') ? `${val} (Kn)`
                                            : val.toUpperCase().includes('WIND') ? `${val} (BF)`
                                            : val.toUpperCase().includes('CURRENTS') ? `${val} (Kn)`
                                            : val;
                                    });
                
                                    let updatedows = [headers.slice(0,6), ...filteredRows.slice(0, rows.length)];            
                                    
                                    const csvContent = updatedows
                                        .map((row) => row?.join(","))?.join("\n");
                
                                    downloadChartInCSVform({ csvContent, name: name });
                                }
                            },
                            "downloadPDF",
                            "downloadPNG",
                            "downloadJPEG",
                            "downloadSVG"
                        ]                
                    }},
                    chartOptions: {
                        chart: {backgroundColor: ThemeColorsForCharts()['platform_background']}
                    },
                    csv: {
                        dateFormat: '%Y-%m-%d'
                    }
                }
            }
        }
    }
    updateState(id, data, widget?.id);
}

export {vesselMonitoringChartUpdate, vesselMonitoringChartPayload, vesselMonitoringChartPayloadLight}
