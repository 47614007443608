import moment from "moment";
import EventsUtilities, {eventsLabels} from 'Utilities/events';
import GlobalUtilities from 'Utilities/global';
import { vesselStore, vesselGroupUtils, vesselGroupStore, calendarUtils } from 'common/store/storeUtils';

const getHeaderTemplate = (label) => {
    return `
    <div ref="eLabel" class="ag-header-cell-label" role="presentation">
        <div ref="eLabel" class="" role="presentation">
            <span ref="eText" class="ag-header-cell-text" role="columnheader" aria-colindex="3">${label}</span>   
        </div>    
        <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span> <!-- Ascending arrow -->
        <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span> <!-- Descending arrow -->
        <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span> <!-- No sort arrow -->
    </div>`
};

const columnDefs = [
    { headerName: "Vessel Name", field: "vesselName", sortable: true, cellRenderer: 'vesselNameHorizontal',
        maxWidth: 240, minWidth: 185, cellStyle: {minWidth: '185px', maxWidth: '190px'},
        sort: "asc", headerComponentParams: {template: getHeaderTemplate('Vessel Name')},
    },
    { headerName: "Date", field: "date", sortable: true,
        maxWidth: 185, minWidth: 165, cellStyle: {minWidth: '185px', maxWidth: '165px', fontWeight: 200},
        sort: "desc", headerComponentParams: {template: getHeaderTemplate('Date')},
    },
    { headerName: "Event Type", field: "type", sortable: true, cellRenderer: 'eventTypeGroup',
        maxWidth: 220, minWidth: 190, cellStyle: {minWidth: '190px', maxWidth: '220px'}
    },
    { headerName: "Description", field: "description", sortable: false, autoHeight: true,
        maxWidth: 600, minWidth: 310, cellStyle: {minWidth: '310px', maxWidth: '550px', fontWeight: 200, paddingRight: '15px', whiteSpace: 'normal'}
    },
];


const fleetLog = (id, response, updateState, extraChartConfigs, widget) => {
    const tableData = response && response.length > 0
    ? response.filter(obj => vesselStore().allVessels.find(vessel => vessel.vesselId === obj.vesselId)).map(object => {
        const dateWithFormat = calendarUtils.belongsToTelegrams(object.type) ? moment(object.dateFrom).format('DD/MM/YYYY HH:mm') : moment(object.dateFrom).format('DD/MM/YYYY');
        const vesselName = (vesselStore().allVessels.find(vessel => vessel.vesselId === object.vesselId))?.name;

        const fleetVessels = vesselGroupUtils.getFleetVesselsFromFleetId(vesselGroupStore().selectedVesselGroup);
        const vesselColor = GlobalUtilities.vesselBgColor(fleetVessels, vesselName);

        return {
            vesselName: vesselName,
            vesselColor: vesselColor,
            date: moment(object.dateFrom).isValid() ? dateWithFormat : '-',
            originalType: object.type,
            type: eventsLabels[object.type],
            description: EventsUtilities.getEventDecription(object)
        };
    })
    : [];

    updateState(id, tableData, widget?.id);
}

export {fleetLog, columnDefs}
