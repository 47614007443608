import React from 'react';
import {ReactComponent as Clock} from 'assets/img/app/global-imgs/time.svg';
import {ReactComponent as OnCourse} from 'assets/img/app/global-imgs/on_course.svg';


const VesselTripPorts = (props) => {
    return (
        <div className="destination-card vesselTripPorts d-flex container-fluid full-height align-items-center justify-content-center">
            <div className="destination-card__content">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="label standaredWhite">
                        <div className="d-flex align-items-center label standaredWhite">
                            <Clock className="marg-r-5" /> Departure (UTC)
                        </div>
                        {props.data && props.data.departureDate ?props.data.departureDate : '-'} 
                    </div>
                    <div className="label standaredWhite">
                        <div className="d-flex align-items-center justify-content-end">
                            <Clock className="marg-r-5" /> Arrival (UTC)
                        </div>
                        {props.data && props.data.arrivalDate ? props.data.arrivalDate : '-'} 
                    </div>
                </div>
                <OnCourse />
                <div className="d-flex align-items-center justify-content-between">
                    <div className="destination-card__group__item headline2 standaredWhite">{props.data ? (props.data.departurePort || '') : ''}</div>
                    <div className="destination-card__group__item headline2 standaredWhite">{props.data ? (props.data.arrivalPort || '') : ''}</div>
                </div>
            </div>
        </div>
    ); 
}

export default VesselTripPorts;