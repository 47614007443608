import Utilities from 'Utilities/global';
import {licensing} from "../../common/store/licensing";
import { vesselUtils, reportsStore } from 'common/store/storeUtils';

const sogPayload = {
    metrics: [
        {
            metricCategory: "VESSEL",
            subIds: [1],
            metricData: [
                {metricName: "speed_overground", aggregation: "AVG"}
            ]
        },
        {
            metricCategory: "TELEGRAM",
            subIds: [1],
            metricData: [
                {metricName: "ve_1_speed_avg", aggregation: "AVG"},
                {metricName: "ordered_speed", aggregation: "AVG"}
            ]
        },
    ]
};


const sog = (id, response, updateState, extraChartConfigs, widget) => {
    const data = [{
        values: [{}, {}, {}]
    }];

    let noData = false;

    const isLight = licensing.lightCondition(null, vesselUtils.getObjOfAVessel(widget?.vesselIds[0]));

    if (response?.series?.length > 0) response.series[0].values.forEach(value => {
        if (value.metricName === "speed_overground") data[0].values[0] = value;
        if (value.metricName === "ve_1_speed_avg") data[0].values[1] = value;
        if (value.metricName === "ordered_speed") data[0].values[2] = value;
    });


    if ( 
        Utilities.hasNotValue(data[0]?.values[0]?.value) 
        && Utilities.hasNotValue(data[0]?.values[1]?.value)
    ) noData = true;
    else noData = false;
    
    let updateStateData = {};
    if (!noData) {
        let firstPart = [
            {
                label: "Avg.", 
                value: data[0].values[0]?.value ? (Utilities.renderNumber(Utilities.roundToDigits(data[0].values[0].value, 1))) + ' ' + (data[0].values[0].unit || '') : "-",
                color: isLight ? "ais" : "sensorsData" 
            },
        ]

        const secondPart = reportsStore().isTelegramConfigured ? [
            {
                label: "Avg.",
                value: data[0].values[1]?.value ? (Utilities.renderNumber(Utilities.roundToDigits(data[0].values[1].value, 1))) + ' ' + (data[0].values[1].unit || '') : "-",
                color: "reportsData" 
            }
        ] : null;

        let thirdPart = reportsStore().isTelegramConfigured ? [
            {
                label: "Instructed", 
                value: data[0].values[2]?.value ? (Utilities.renderNumber(Utilities.roundToDigits(data[0].values[2].value, 1))) + ' ' + (data[0].values[2].unit || '') : "-",
                color: "reportsData" 
            }
        ] : null;

        if (isLight && !data[0].values[0].value) firstPart = null
        if (!data[0].values[2].value) thirdPart = null
    
        updateStateData = {
            firstPart,
            secondPart,
            thirdPart,
            firstDevider: firstPart && secondPart ? true : false,
            secondDevider: (firstPart || secondPart) &&  thirdPart ? true : false,
        };
    }

    updateState(id, updateStateData, widget?.id);
}


export {sogPayload, sog};
