import React from 'react';
import moment from "moment/moment";


const Period = ({ period }) => {
    return (
        <div className={`period period-${period.type}`}>
            <span className="text-nowrap">{moment(period.timestampFrom).utc().format("DD/MM/YYYY HH:mm")}</span> &nbsp; - &nbsp; <span className="text-nowrap">{moment(period.timestampTo).utc().format("DD/MM/YYYY HH:mm")}</span>
        </div>
    );
}

const ReportPeriod = ({periods}) => {

    return <div className="report-period">
        PERIOD:
        <div className="report-period__items">
            {
                periods?.map((period, key) => <Period period={period} key={key} />)
            }
        </div>
    </div>
}

export default ReportPeriod;
